import { Link } from "@/ui/link";
import { ReactNode } from "react";
import * as css from "./style.css";

export function BackButton(props: { href: string }) {
  return (
    <Link href={props.href} className={css.backButton}>
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
        <title>Back</title>
        <path
          d="M15.3506955,29.1086932 C20.4197309,26.0342966 24.3028324,23.2029709 27,20.614716 C23.8750878,17.9178812 19.5024121,14.883117 13.8819729,11.5104234 L21.8305566,20.614716 L15.3506955,29.1086932 Z"
          transform="translate(20.441, 20.3096) scale(-1, -1) translate(-20.441, -20.3096)"
        />
      </svg>
    </Link>
  );
}

export function CloseButton(props: { href: string }) {
  return (
    <Link href={props.href} className={css.backButton}>
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
        <title>Back</title>
        <g>
          <path
            d="M19.7950413,9 C20.2850813,10.4793814 21.2145658,14.2578982 21.4015521,18.5119422 C25.6904889,18.657261 29.5105293,19.4290466 31,19.92245 C29.9161318,20.5586335 25.2182432,21.194817 21.406857,21.3306953 C21.2374836,25.6488846 20.2911051,29.5024068 19.7950413,31 C19.4298163,29.2033922 18.7392999,25.0205897 18.5929351,21.3291715 C14.8888553,21.192497 10.6228379,20.5574735 9,19.92245 C10.4894707,19.4290466 14.3095111,18.657261 18.5990431,18.5117565 C18.7588884,14.8608027 19.4350141,10.7710135 19.7950413,9 Z"
            transform="translate(20, 20) rotate(-45) translate(-20, -20)"
          ></path>
        </g>
      </svg>
    </Link>
  );
}

export function DesktopViewHeader(props: { menu: ReactNode; label: string; back?: ReactNode }) {
  return (
    <header className={css.header}>
      {props.back}

      <div className={css.label}>{props.label}</div>

      {props.menu}
    </header>
  );
}
