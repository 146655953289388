import { Form, useStoreState } from "@ariakit/react";
import { client } from "../client";
import { FormSubmitButton } from "../form-submit-button";
import { FormSubmitError } from "../form-submit-error";
import { toKnownFailureMessage, useForm, validEmail } from "../hooks/use-form";
import { useNavigate } from "../hooks/use-navigate";
import { IndieTabletopClubFooter, IndieTabletopClubSymbol } from "../indie-tabletop-club-brand";
import { FormTextField } from "../inputs";
import { InputsGroup } from "../inputs/group";
import { Link } from "../link";
import { getHistoryState } from "../link/history-state";
import { ViewContainer } from "../max-width-container";
import { MobileNavLayout } from "../mobile-nav-layout";
import { useCurrentUser } from "../store/hooks";
import * as common from "../utils.css";

export function LoginPage() {
  const navigate = useNavigate();
  const currentUserEmail = useCurrentUser()?.email;
  const historyStateEmail = getHistoryState()?.emailValue;
  const initialEmailValue = currentUserEmail ?? historyStateEmail ?? "";

  const { form, submitName } = useForm({
    defaultValues: {
      email: initialEmailValue,
      password: "",
    },
    validate: {
      email: validEmail,
    },
    async onSubmit(state) {
      const op = await client.login(state.values);

      return op.mapFailure((failure) => {
        if (failure.type === "API_ERROR" && failure.code === 401) {
          return "Username and password do not match. Please try again.";
        }

        if (failure.type === "API_ERROR" && failure.code === 404) {
          return "Could not find a user with this email.";
        }

        return toKnownFailureMessage(failure);
      });
    },
    onSuccess: () => navigate("~/"),
  });

  const emailValue = useStoreState(form, (s) => s.values.email);

  return (
    <MobileNavLayout title="Login">
      <ViewContainer padding="small">
        <div className={common.section}>
          <div className={common.formHeader}>
            <IndieTabletopClubSymbol />

            <h1 className={common.heading}>Log in</h1>

            <p className={common.paragraph}>
              Log into your Indie Tabletop Club account to enable backup and sync, quick start
              rules, and more! Do not have an account?{" "}
              <Link href="/join" className={common.interactiveText} state={{ emailValue }}>
                Join now.
              </Link>
            </p>
          </div>

          <Form store={form}>
            <InputsGroup>
              <FormTextField
                name={form.names.email}
                label="Email"
                placeholder="E.g. guruk@rotvarlden.com"
                type="email"
                autoComplete="username"
                required
              />

              <FormTextField
                name={form.names.password}
                label="Password"
                type="password"
                placeholder="Your password"
                autoComplete="current-password"
                required
              />

              <FormSubmitError name={submitName} />
            </InputsGroup>

            <div className={common.formSubmitArea}>
              <FormSubmitButton label="Log in" />
            </div>
          </Form>

          <footer className={common.formFooter}>
            <p className={common.paragraph}>
              Forgot password?{" "}
              <Link href="/password" className={common.interactiveText} state={{ emailValue }}>
                Reset it.
              </Link>
            </p>
          </footer>

          <IndieTabletopClubFooter />
        </div>
      </ViewContainer>
    </MobileNavLayout>
  );
}
