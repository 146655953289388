import { assert } from "../lib/assert.js";
import { KeywordRefData, NumericalQueryData } from "../schema/latest.js";
import { Sort } from "../lib/sort.js";
import { isOneOf } from "../lib/typeguards.js";
import { CursedArtefact } from "./artefact.js";
import { KeywordReasonType } from "./enums.js";
import { Footprint } from "./footprint.js";
import { KeywordDefinition } from "./keyword-definition.js";
import {
  KeywordReason,
  RESERVED_KEYWORDS_IDS,
  replaceKeywordValue,
  toNumericalQuery,
} from "./keyword-types.js";
import { NumericalKeywordType } from "./numerical-keyword-type.js";
import { Ruleset } from "./ruleset.js";
import { Trait, injectTraits } from "./traits.js";
import { UnitType } from "./unit-type.js";
import { Unit } from "./unit.js";
import { UnitValidationError } from "./validation.js";

function getNumericalKeywordCost(keyword: KeywordDefinition, value: number) {
  const purchaseOption = keyword.purchaseOptions.find((cost) => cost.value === value);

  assert(
    purchaseOption,
    `Purchased keyword must have an associated cost, but none found for ${keyword.name} with value ${value}.`,
  );

  return purchaseOption.cost;
}

function getKeywordCost(keyword: KeywordDefinition) {
  const purchaseOption = keyword.purchaseOptions[0]; // Non-numerical keywords only have a single purchase option

  assert(
    purchaseOption,
    `Purchased keyword must have an associated cost, but none found for ${keyword.name}.`,
  );

  return purchaseOption.cost;
}

function getReason(
  type: KeywordReasonType,
  keywordCost: number,
  sourceKeywordName: string | null,
): KeywordReason {
  switch (type) {
    case KeywordReasonType.DEFAULT_KEYWORD:
      return { type };

    case KeywordReasonType.PURCHASED_BY_PLAYER:
      return { type, cost: keywordCost };

    case KeywordReasonType.ADDED_BY_KEYWORD:
      assert(
        sourceKeywordName,
        `Source keyword name must be provided when keyword reason is ${KeywordReasonType.ADDED_BY_KEYWORD}.`,
      );
      return { type, keywordName: sourceKeywordName };

    case KeywordReasonType.ADDED_BY_CURSED_ARTEFACT:
      assert(
        sourceKeywordName,
        `Source Cursed Artefact name must be provided when keyword reason is ${KeywordReasonType.ADDED_BY_CURSED_ARTEFACT}.`,
      );
      return { type, cursedArtefactName: sourceKeywordName };

    case KeywordReasonType.ASSIGNED_BY_PLAYER:
      return { type };
  }
}

function isPurchasedOrAdded(reason: KeywordReasonType) {
  switch (reason) {
    case KeywordReasonType.PURCHASED_BY_PLAYER:
    case KeywordReasonType.ASSIGNED_BY_PLAYER:
      return true;
    default:
      return false;
  }
}

function getReasonNote(reason: KeywordReason, costMultiplier: number): string {
  switch (reason.type) {
    case KeywordReasonType.ASSIGNED_BY_PLAYER:
      return `Assigned for free (once per army).`;

    case KeywordReasonType.PURCHASED_BY_PLAYER:
      return `Purchased for ${reason.cost * costMultiplier}pts.`;

    case KeywordReasonType.DEFAULT_KEYWORD:
      return `Default unit keyword.`;

    case KeywordReasonType.ADDED_BY_KEYWORD:
      return `Added by the ${reason.keywordName} keyword.`;

    case KeywordReasonType.ADDED_BY_CURSED_ARTEFACT:
      return `Added by ${reason.cursedArtefactName} artefact.`;
  }
}

class NumericalKeywordIncrement {
  value: number;
  type: NumericalKeywordType;
  reason: KeywordReason;
  cost?: number;
  keyword: Keyword;

  constructor(
    data: {
      value: number;
      type: NumericalKeywordType;
      reason: KeywordReason;
      cost?: number;
    },
    keyword: Keyword,
  ) {
    this.value = data.value;
    this.type = data.type;
    this.reason = data.reason;
    this.cost = data.cost;
    this.keyword = keyword;
  }

  get name() {
    const operator = this.type === NumericalKeywordType.INCREMENT ? "+" : "";
    const value = `${operator}${this.value}`;
    return replaceKeywordValue(this.keyword.definition.name, value);
  }
}

type KeywordSource = CursedArtefact | Keyword | Unit | null;

export class Keyword {
  readonly id: string;
  readonly definition: KeywordDefinition;
  readonly source: KeywordSource | null;
  readonly unit: Unit;
  readonly ruleset: Ruleset;

  constructor(
    unit: Unit,
    props: {
      keywordDef: KeywordDefinition;
      source: KeywordSource | null;
    },
  ) {
    this.unit = unit;
    this.ruleset = unit.ruleset;
    this.id = props.keywordDef.id;
    this.source = props.source;
    this.definition = props.keywordDef;
  }

  get descriptionWithTraits() {
    return injectTraits(this.definition.description, this.definition.traits);
  }

  get reason() {
    if (this.source instanceof CursedArtefact) {
      return KeywordReasonType.ADDED_BY_CURSED_ARTEFACT;
    }

    if (this.source instanceof Keyword) {
      return KeywordReasonType.ADDED_BY_KEYWORD;
    }

    if (this.source instanceof Unit) {
      return KeywordReasonType.DEFAULT_KEYWORD;
    }

    if (this.isReserved()) {
      return KeywordReasonType.ASSIGNED_BY_PLAYER;
    }

    return KeywordReasonType.PURCHASED_BY_PLAYER;
  }

  get cost() {
    return this.reason === KeywordReasonType.PURCHASED_BY_PLAYER ?
        getKeywordCost(this.definition)
      : 0;
  }

  get note() {
    return getReasonNote(
      getReason(this.reason, this.cost, this.source?.name ?? null),
      this.unit.keywordCostMultiplier,
    );
  }

  get name() {
    return this.definition.name;
  }

  isNumerical(this: Keyword): this is NumericalKeyword {
    return this instanceof NumericalKeyword;
  }

  isPurchasedOrAdded(this: Keyword) {
    return isPurchasedOrAdded(this.reason);
  }

  isReserved(this: Keyword) {
    return RESERVED_KEYWORDS_IDS.includes(this.id);
  }

  hasTrait(this: Keyword, trait: Trait) {
    return this.definition.traits.some((t) => t === trait);
  }

  validate(): UnitValidationError | null {
    if (this.unit.type === UnitType.ARTILLERY && this.hasTrait(Trait.WEAKNESS)) {
      return isOneOf(this.id, ["self_destructive", "short_ranged"]) ? null : (
          {
            type: "KEYWORD_CONSTRAINTS_FAILED",
            keyword: this,
            message: `A unit with the Artillery type may only purchase the Self-Destructive and Short-Ranged weaknesses, but the ${this.name} weakness was purchased.`,
          }
        );
    }

    if (this.id === "wide_footprint") {
      return this.unit.profile.footprint === Footprint.NARROW ?
          null
        : {
            type: "KEYWORD_CONSTRAINTS_FAILED",
            keyword: this,
            message: `A unit may only purchase the Wide Footprint weakness if a Narrow footprint is listed on its profile.`,
          };
    }

    if (this.id === "narrow_footprint") {
      return this.unit.profile.footprint === Footprint.WIDE ?
          null
        : {
            type: "KEYWORD_CONSTRAINTS_FAILED",
            keyword: this,
            message: `A unit may only purchase the Narrow Footprint strength if a Wide footprint is listed on its profile.`,
          };
    }

    if (this.id === "short_ranged") {
      return this.unit.hasKeyword("ranged") ?
          null
        : {
            type: "KEYWORD_CONSTRAINTS_FAILED",
            keyword: this,
            message: `A unit that do not contain the Ranged keyword cannot purchase the Short-Ranged weakness.`,
          };
    }

    if (this.id === "horde") {
      return (
          isOneOf(this.unit.type, [
            UnitType.LIGHT_INFANTRY,
            UnitType.RANGED_INFANTRY,
            UnitType.HEAVY_INFANTRY,
            UnitType.MONSTROUS_INFANTRY,
          ])
        ) ?
          null
        : {
            type: "KEYWORD_CONSTRAINTS_FAILED",
            keyword: this,
            message: `A unit may only purchase the Horde strength if its unit type contains “Infantry” in its name.`,
          };
    }

    return null;
  }

  isValid(this: Keyword) {
    if (this.unit.type === UnitType.ARTILLERY && this.hasTrait(Trait.WEAKNESS)) {
      return isOneOf(this.id, ["self_destructive", "short_ranged"]);
    }

    if (this.id === "wide_footprint") {
      return this.unit.profile.footprint === Footprint.NARROW;
    }

    if (this.id === "narrow_footprint") {
      return this.unit.profile.footprint === Footprint.WIDE;
    }

    if (this.id === "short_ranged") {
      return this.unit.hasKeyword("ranged");
    }

    if (this.id === "horde") {
      return isOneOf(this.unit.type, [
        UnitType.LIGHT_INFANTRY,
        UnitType.RANGED_INFANTRY,
        UnitType.HEAVY_INFANTRY,
        UnitType.MONSTROUS_INFANTRY,
      ]);
    }

    return true;
  }

  static fromKeywordRefData(
    unit: Unit,
    keywordRefData: KeywordRefData,
    source: KeywordSource = null,
  ) {
    const { id: keywordId, numerical } = keywordRefData;

    const ruleset = unit.ruleset;
    const keywordDef = ruleset.keywordsDefsById.getOrThrow(keywordId);

    if (numerical) {
      return new NumericalKeyword(unit, {
        keywordDef,
        numerical,
        source,
      });
    }

    return new Keyword(unit, {
      keywordDef,
      source,
    });
  }
}

export class NumericalKeyword extends Keyword {
  increments: NumericalKeywordIncrement[];

  constructor(
    unit: Unit,
    props: {
      keywordDef: KeywordDefinition;
      numerical: NumericalQueryData;
      source: KeywordSource | null;
    },
  ) {
    super(unit, props);

    const cost =
      this.reason === KeywordReasonType.PURCHASED_BY_PLAYER ?
        getNumericalKeywordCost(this.definition, props.numerical.value)
      : 0;

    const numerical = toNumericalQuery(props.numerical);
    this.increments = [
      new NumericalKeywordIncrement(
        {
          ...numerical,
          reason: getReason(this.reason, cost, this.source?.name ?? null),
          cost,
        },
        this,
      ),
    ];
  }

  mergeNumericalKeywords(this: NumericalKeyword, keyword: NumericalKeyword) {
    this.increments.push(...keyword.increments);
  }

  override get name() {
    return replaceKeywordValue(this.definition.name, this.value);
  }

  override get descriptionWithTraits() {
    return injectTraits(
      replaceKeywordValue(this.definition.description, this.value),
      this.definition.traits,
    );
  }

  get value() {
    return this.increments
      .sort((left, right) => {
        if (left.type === NumericalKeywordType.BASE) {
          // If both increments are BASE, compare their values and sort them ascending
          if (right.type === NumericalKeywordType.BASE) {
            return left.value - right.value;
          }

          // Otherwise BASE should always be before INCREMENT
          return Sort.MoveLeftUp;
        }

        // Otherwise order doens't matter.
        return Sort.NoMove;
      })
      .reduce((sum, increment) => {
        switch (increment.type) {
          case NumericalKeywordType.BASE: {
            return increment.value;
          }

          case NumericalKeywordType.INCREMENT: {
            return sum + increment.value;
          }
        }
      }, 0);
  }

  override get note() {
    if (this.increments.length === 1) {
      return getReasonNote(this.increments[0].reason, this.unit.keywordCostMultiplier);
    }

    return this.increments
      .map((increment) => {
        return `${increment.name}: ${getReasonNote(
          increment.reason,
          this.unit.keywordCostMultiplier,
        )}`;
      })
      .join(" ");
  }

  override isPurchasedOrAdded(this: NumericalKeyword) {
    return this.increments.some((increment) => {
      return isPurchasedOrAdded(increment.reason.type);
    });
  }
}
