import { CurrentUser } from "@indietabletop/appkit/types";
import { ReactNode } from "react";
import menuUrl from "../../images/menu.svg";
import { Link } from "../link";
import { useCurrentUser, useHasAuthError, useIsSyncing, useSyncLog } from "../store/hooks";
import { SyncIcon, SyncIconStatus } from "../sync-icon";
import { color } from "../tokens";
import { useNavControls } from "./context";
import * as css from "./style.css";

function OpenSidebarButton() {
  const { toggleOpen } = useNavControls();

  return (
    <button className={css.navButton} type="button" onClick={toggleOpen}>
      <img src={menuUrl} alt="Menu" />
    </button>
  );
}

function BackButton(props: { href: string }) {
  return (
    <Link href={props.href} className={css.navButton}>
      <svg
        style={{ transform: "scale(0.8) translateX(-15%)" }}
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.24537e-07 12L20.5714 24L11.7195 12L20.5714 -8.99206e-07L5.24537e-07 12Z"
          fill="white"
        />
      </svg>
    </Link>
  );
}

export type MobileNavProps = {
  backHref?: string;
  title: ReactNode;
  action?: ReactNode;
  withShadow?: boolean;
};

export function MobileNav({ withShadow, ...props }: MobileNavProps) {
  return (
    <div className={css.headerNav({ withShadow })}>
      <div>
        {props.backHref ?
          <BackButton href={props.backHref} />
        : <OpenSidebarButton />}
      </div>

      <div className={css.headerTitle}>{props.title}</div>

      <div className={css.action}>{props.action}</div>
    </div>
  );
}

function getStatusAndTitle({
  currentUser,
  isSyncing,
  hasAuthError,
  hasSyncError,
}: {
  currentUser: CurrentUser | null;
  isSyncing: boolean;
  hasAuthError: boolean;
  hasSyncError: boolean;
}): { status: SyncIconStatus; title: string } {
  if (!currentUser) {
    return { status: "unsynced", title: "Not logged in" };
  }

  if (hasAuthError) {
    return { status: "error", title: "Authentication failed" };
  }

  if (hasSyncError) {
    return { status: "error", title: "Sync error" };
  }

  if (!currentUser.isVerified) {
    return { status: "unverified", title: "Account not verified" };
  }

  if (isSyncing) {
    return { status: "syncing", title: "Syncing..." };
  }

  return { status: "synced", title: "Sync complete" };
}

function SessionIndicator() {
  const currentUser = useCurrentUser();
  const isSyncing = useIsSyncing();
  const hasAuthError = useHasAuthError();
  const hasSyncError = useSyncLog().at(0)?.type === "SYNC_ERROR";

  const { status, title } = getStatusAndTitle({
    currentUser,
    isSyncing,
    hasAuthError,
    hasSyncError,
  });

  return (
    <Link href="~/account" className={css.navButton}>
      <div
        style={{
          position: "relative",
          inlineSize: "3rem",
          blockSize: "3rem",
          borderRadius: "50%",
          backgroundColor: "hsl(0 0% 60%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          width="16px"
          height="21px"
          viewBox="0 0 16 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Account</title>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-50, -734)" fill="#19262E" fillRule="nonzero">
              <g transform="translate(50, 734)">
                <path d="M16,21 L0,21 L0,19 C0,16.2386 2.23858,14 5,14 L11,14 C13.7614,14 16,16.2386 16,19 L16,21 Z M8,12 C4.68629,12 2,9.3137 2,6 C2,2.68629 4.68629,0 8,0 C11.3137,0 14,2.68629 14,6 C14,9.3137 11.3137,12 8,12 Z"></path>
              </g>
            </g>
          </g>
        </svg>
        <div
          style={{
            position: "absolute",
            bottom: "-0.5rem",
            right: "-0.5rem",
            border: `3px solid ${color.CHARCOAL_BLUE}`,
            borderRadius: "50%",
          }}
        >
          <SyncIcon status={status} title={title} />
        </div>
      </div>
    </Link>
  );
}

export function Spine(props: { isRoot: boolean }) {
  const { toggleOpen } = useNavControls();

  return (
    <div className={css.spine({ withDivider: props.isRoot })}>
      <button className={css.navButton} type="button" onClick={toggleOpen}>
        <img src="/logos/logo-small.png" alt="" className={css.logo} />
        <span className={css.menuText}>Menu</span>
      </button>

      <div className={css.text}>
        <div className={css.gameTitle}>
          <Link href="~/">Mike Hutchinson’s Hobgoblin</Link>
        </div>
        <div className={css.gameSubtitle}>Brutal Fantasy Battles</div>
      </div>

      <SessionIndicator />
    </div>
  );
}
