import { Link } from "@/ui/link";
import {
  DialogDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuProvider,
  MenuSeparator,
  MenuStoreState,
} from "@ariakit/react";
import { ReactElement, ReactNode } from "react";
import * as css from "./style.css";

export function MoreMenu(props: {
  fallbackLabel: string;
  children: ReactNode;
  iconColor?: string;
  className?: string;
  placement?: MenuStoreState["placement"];
  icon?: ReactElement;
}) {
  return (
    <MenuProvider placement={props.placement ?? "top-end"}>
      <MenuButton className={props.className ?? css.menuButton}>
        {props.icon ?? (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill={props.iconColor ?? "#232424"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{props.fallbackLabel}</title>
            <circle cx="12" cy="20" r="2" />
            <circle cx="20" cy="20" r="2" />
            <circle cx="28" cy="20" r="2" />
          </svg>
        )}
      </MenuButton>

      <Menu className={css.menu}>{props.children}</Menu>
    </MenuProvider>
  );
}

export function MoreMenuItem(props: Omit<MenuItemProps, "className">) {
  return <MenuItem {...props} className={css.menuItem} />;
}

export function MoreMenuLinkItem(
  props: Omit<MenuItemProps, "className" | "render"> & { href: string },
) {
  return <MenuItem {...props} className={css.menuItem} render={<Link href={props.href} />} />;
}

export function MoreMenuDialogDisclosureItem(props: Omit<MenuItemProps, "className" | "render">) {
  return <MenuItem {...props} className={css.menuItem} render={<DialogDisclosure />} />;
}

export function MoreMenuSeparator() {
  return <MenuSeparator className={css.menuSeparator} />;
}
