import { useDatabase } from "@/ui/database-provider";
import { KnownErrorMessage } from "@/ui/db-error";
import { useResolveVersion } from "@/ui/game-content-provider";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { MobileNavLayout } from "@/ui/mobile-nav-layout";
import { fold } from "@indietabletop/appkit/async-op";
import { FortuneHand } from "../fortune-hand";
import { FortunesProvider, useFortuneCards } from "../fortunes-context";
import { PlayingCard } from "../playing-card";
import * as css from "./style.css";

function FortunePageMain() {
  const database = useDatabase();
  const fortuneCardsOp = useFortuneCards();
  const rulesetVersion = fortuneCardsOp.valueOrNull()?.ruleset_version;
  const rulesetOp = useResolveVersion(rulesetVersion);
  const op = fold([fortuneCardsOp, rulesetOp]);

  if (op.isPending) {
    return <LoadingIndicator />;
  }

  if (op.isFailure) {
    return <KnownErrorMessage error={op.failure} />;
  }

  const [fortuneHand, ruleset] = op.value;

  return (
    <>
      <FortuneHand fortune={fortuneHand} ruleset={ruleset} />

      <div className={css.cards}>
        {ruleset.fortunes.map((fortune) => {
          return fortune.cards.map((card) => {
            return (
              <PlayingCard
                isUsed={fortuneHand.playing_cards_ids.includes(card.id)}
                key={card.id}
                id={card.id}
                suite={card.suite}
                value={card.value}
                name={fortune.name}
                description={fortune.instructions}
                onAddToHand={async () => {
                  await database.updateFortuneHand({
                    ...fortuneHand,
                    playing_cards_ids: [...fortuneHand.playing_cards_ids, card.id],
                  });
                }}
              />
            );
          });
        })}
      </div>
    </>
  );
}

export function FortunePage() {
  return (
    <FortunesProvider>
      <MobileNavLayout title="Fortune Cards">
        <FortunePageMain />
      </MobileNavLayout>
    </FortunesProvider>
  );
}
