import { VersionChangeManager } from "@/oss/modern-idb";
import { IndexesV3, SchemaV4 } from "@/schema/schema-v4.js";
import { ArmyDataV5, SchemaV5 } from "@/schema/schema-v5.js";

type Manager = VersionChangeManager<SchemaV5 | SchemaV4, IndexesV3>;

export async function migrateToV5(manager: Manager) {
  const store = manager.transaction.objectStore("armies");

  for await (const cursor of store.openCursor()) {
    const army = cursor.value;
    if (!army.deleted && "image_id" in army) {
      const { image_id: imageId, ...unchanged } = army;

      const armyV5: ArmyDataV5 = {
        ...unchanged,
        images: imageId ? [{ type: "ILLUSTRATION", id: imageId }] : [],
      };

      await cursor.update(armyV5);
    }
  }
}
