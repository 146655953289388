import { useQuery } from "@/ui/database-provider/hooks";
import { ReactNode, useCallback } from "react";
import { ArmiesRulesetsContext } from "./context";

export function ArmiesRulesetsFetcher(props: { children: ReactNode }) {
  const result = useQuery(useCallback((db) => db.getArmiesRulesets(), []));
  return (
    <ArmiesRulesetsContext.Provider value={result}>{props.children}</ArmiesRulesetsContext.Provider>
  );
}
