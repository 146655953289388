import { BattleSection } from "@/battle/section";
import { SectionsContainer } from "@/battle/sections-container";
import { formatRolls } from "@/domain/utils";
import { LookupMap } from "@/lib/lookup";
import { isString } from "@/lib/typeguards";
import { Body } from "@/ui/body";
import { KnownErrorMessage } from "@/ui/db-error";
import { RulesetProvider } from "@/ui/game-content-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { Image } from "@/ui/image";
import { KeywordsHandler } from "@/ui/keywords-handler";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { MobileNavLayout } from "@/ui/mobile-nav-layout";
import { MoreMenu, MoreMenuItem, MoreMenuLinkItem, MoreMenuSeparator } from "@/ui/more-menu";
import { fold } from "@indietabletop/appkit/async-op";
import { ReactNode } from "react";
import { useUserSettingsOp } from "../../ui/user-settings/context";
import { BattleStateProvider } from "../battle-state";
import { BattleState, useBattleStateOp } from "../battle-state/context";
import { ShareBattleDialog } from "../share-battle-dialog";
import { VersionMismatchPopover } from "../version-mismatch-popover";
import * as css from "./style.css";

export function Heading(props: { children: ReactNode }) {
  return <h2 className={css.heading}>{props.children}</h2>;
}

function SectionIllustration(props: { entryId: string | string[] }) {
  const illustrations = LookupMap.fromObject<string, string>({
    pets: "/images/familiar.webp",
    bloodbath: "/images/ice-zombies.webp",
    scouts: "/images/elf-archer.webp",
  });

  const illustrationUrl = isString(props.entryId) ? illustrations.getOr(props.entryId, null) : null;

  if (illustrationUrl) {
    return (
      <div className={css.illustrationContainer}>
        <Image className={css.illustration} src={illustrationUrl} width={700} height={640} />
      </div>
    );
  }

  return null;
}

function Section(props: { children: ReactNode }) {
  return <section className={css.section}>{props.children}</section>;
}

function BattlePageMain() {
  const userSettingsOp = useUserSettingsOp();
  const battleStateOp = useBattleStateOp();
  const op = fold([userSettingsOp, battleStateOp]);

  if (op.isPending) {
    return <LoadingIndicator />;
  }

  if (op.isFailure) {
    return <KnownErrorMessage error={op.failure} />;
  }

  const [userSettings, battle] = op.value;
  const { scenario, battlefield, deployment, twist, ruleset } = battle.state;

  return (
    <RulesetProvider ruleset={ruleset}>
      <KeywordsHandler>
        <VersionMismatchPopover
          battleStateGameVersion={ruleset.version}
          battleSettingsGameVersion={userSettings.ruleset.version}
        />
        <SectionsContainer {...battle}>
          <BattleSection sectionId="scenario" heading="Scenario">
            {scenario && (
              <>
                <Heading>
                  {formatRolls(scenario.rolls)}: {scenario.name}
                </Heading>
                <blockquote className={css.flavour}>{scenario.flavour}</blockquote>
                <Body html={scenario.instructions} />
                <SectionIllustration entryId={scenario.id} />
              </>
            )}
          </BattleSection>
          <BattleSection sectionId="battlefield" heading="Battlefield">
            {battlefield &&
              battlefield.map((entry) => {
                return (
                  <Section key={entry.id}>
                    <Heading>
                      {formatRolls(entry.rolls)}: {entry.name}
                    </Heading>
                    <Body html={entry.instructions} />
                  </Section>
                );
              })}
          </BattleSection>
          <BattleSection sectionId="deployment" heading="Deployment">
            {deployment && (
              <>
                <Heading>
                  {formatRolls(deployment.rolls)}: {deployment.name}
                </Heading>
                <Body html={deployment.instructions} />
                <SectionIllustration entryId={deployment.id} />
              </>
            )}
          </BattleSection>
          <BattleSection sectionId="twist" heading="Twist">
            {twist && (
              <>
                <Heading>
                  {formatRolls(twist.rolls)}: {twist.name}
                </Heading>
                <Body html={twist.instructions} />
                <SectionIllustration entryId={twist.id} />
              </>
            )}
          </BattleSection>
        </SectionsContainer>
      </KeywordsHandler>
    </RulesetProvider>
  );
}

function ShareBattleMenuItem(props: { battleState: BattleState }) {
  const { store } = useDialog();
  return (
    <>
      <ShareBattleDialog store={store} battleState={props.battleState} />
      <MoreMenuItem onClick={store.show}>Share battle</MoreMenuItem>
    </>
  );
}

function BattleMoreMenu() {
  const battleStateOp = useBattleStateOp();

  if (!battleStateOp.isSuccess) {
    return null;
  }

  const { actions, state } = battleStateOp.value;

  return (
    <MoreMenu fallbackLabel="Battle actions" iconColor="white">
      <ShareBattleMenuItem battleState={state} />
      <MoreMenuLinkItem href="/settings">Settings</MoreMenuLinkItem>
      <MoreMenuSeparator />
      <MoreMenuItem onClick={actions.rerollAll}>Reroll all</MoreMenuItem>
    </MoreMenu>
  );
}

export function BattlePage() {
  return (
    <BattleStateProvider>
      <MobileNavLayout title="Chaos of War" action={<BattleMoreMenu />}>
        <BattlePageMain />
      </MobileNavLayout>
    </BattleStateProvider>
  );
}
