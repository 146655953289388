import { Image } from "@/ui/image";
import { Dialog, DialogDismiss, DialogStore } from "@ariakit/react";
import { ReactNode } from "react";
import * as css from "./style.css";

type Theme = "light" | "dark";

function getIconSrc(theme: Theme) {
  return theme === "light" ? `/icons/cross.svg` : `/icons/cross-white.svg`;
}

export function Popup(props: {
  children: ReactNode;
  label: ReactNode;
  store: DialogStore;
  theme: Theme;
  modal: boolean;
}) {
  return (
    <Dialog store={props.store} className={css.popover} modal={props.modal}>
      <div className={css.card[props.theme]}>
        <div className={css.header}>
          {props.label}

          <DialogDismiss className={css.closeButton}>
            <Image src={getIconSrc(props.theme)} alt="Dismiss" width={40} height={40} />
          </DialogDismiss>
        </div>

        {props.children}
      </div>
    </Dialog>
  );
}
