import { DialogStore } from "@ariakit/react";
import { ReactNode } from "react";
import * as css from "./style.css";
import { ModalDialog } from "../modal-dialog";

export function InfoDialog(props: { children: ReactNode; store: DialogStore }) {
  return (
    <ModalDialog store={props.store} className={css.dialog}>
      <div className={css.inner}>{props.children}</div>
    </ModalDialog>
  );
}
