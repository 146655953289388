/**
 * When writing custom `compareFn` for `Array.prototype.sort()` or
 * `Array.prototype.toSorted()`, it can be hard to remember how does
 * the return value affect the sort.
 *
 * Use this simple enum to give the return values more explicit names.
 *
 * @example
 * ```ts
 * // We want to move "important" up, and leave the rest of the items
 * // in the original order.
 *
 * ["a", "important", "b"].sort((left, right) => {
 *   if (left === "important") {
 *     return Sort.MoveLeftUp;
 *   }
 *
 *   if (right === "important") {
 *     return Sort.MoveRightUp;
 *   }
 *
 *   return Sort.NoMove
 * })
 * ````
 */
export enum Sort {
  MoveLeftUp = -1,
  MoveRightUp = 1,
  NoMove = 0,
}

export function sortByImportance<T extends { id: string }>(leftKeyword: T, rightKeyword: T) {
  if (leftKeyword.id === "general") {
    return Sort.MoveLeftUp;
  }

  if (rightKeyword.id === "general") {
    return Sort.MoveRightUp;
  }

  if (leftKeyword.id === "battle_standard") {
    return Sort.MoveLeftUp;
  }

  if (rightKeyword.id === "battle_standard") {
    return Sort.MoveRightUp;
  }

  if (leftKeyword.id < rightKeyword.id) {
    return Sort.MoveLeftUp;
  }

  if (leftKeyword.id === rightKeyword.id) {
    return Sort.NoMove;
  }

  return Sort.MoveRightUp;
}
