export function toVersionName(version: string) {
  const { major, minor, patch } = parseSemver(version);

  return `v${major}.${minor}${patch > 0 ? `.${patch}` : ""}${major === 0 ? " (Early Access)" : ""}`;
}

export function parseSemver(semver: string) {
  const [major, minor, patch] = semver.split(".").map((versionString) => {
    const versionNumber = Number(versionString);

    if (versionNumber < 0) {
      throw new Error(
        `Could not parse '${semver}' value as SemVer. ${versionNumber} is negative, but only positive numbers are allowed.`,
      );
    }

    return versionNumber;
  });

  return { minor, major, patch };
}
