import { VersionChangeManager } from "@/oss/modern-idb";
import { getStringArrayFromCookie, removeCookie } from "../client-cookie";
import { isNonEmpty } from "../typeguards";
import { IndexesV1 } from "../../schema/schema-v1";
import { SchemaV2, IndexesV2 } from "../../schema/schema-v2";

type Manager = VersionChangeManager<SchemaV2, IndexesV2 | IndexesV1>;

async function migrateArmiesToV2(manager: Manager) {
  const store = manager.transaction.objectStore("armies");

  // In v1, armies did not have a version. Let's add it.
  for await (const cursor of store.openCursor()) {
    await cursor.update({ ...cursor.value, ruleset_version: "0.2.0" });
  }

  // Index name has been changes to omit unnecessary store namespacing.
  store.deleteIndex("armies_created_index");
  store.createIndex("created_idx", "created");

  // Create ruleset_version index
  store.createIndex("ruleset_version_idx", "ruleset_version");

  console.info("Migrated 'armies' from v1.");
}

async function migrateBattlesToV2(manager: Manager) {
  // Settings store didn't exist in v1, so we create it.
  const settings = manager.createObjectStore("settings", {
    keyPath: "ruleset_version",
  });

  // In v1, excluded items were stored in a cookie
  const scenariosExclIds = getStringArrayFromCookie("scenario_exclusions");
  const battlefieldsExclIds = getStringArrayFromCookie("battlefield_exclusions");
  const deploymentsExclIds = getStringArrayFromCookie("deployment_exclusions");
  const twistsExclIds = getStringArrayFromCookie("twist_exclusions");

  const armiesCount = await manager.transaction.objectStore("armies").count();

  // If the user has an existing configuration, or they created some armies
  // create a legacy settings object in the database.
  if (
    isNonEmpty(scenariosExclIds) ||
    isNonEmpty(battlefieldsExclIds) ||
    isNonEmpty(deploymentsExclIds) ||
    isNonEmpty(twistsExclIds) ||
    armiesCount > 0
  ) {
    await settings.put({
      ruleset_version: "0.2.0",
      excluded_scenarios_ids: scenariosExclIds,
      excluded_battlefields_ids: battlefieldsExclIds,
      excluded_deployments_ids: deploymentsExclIds,
      excluded_twists_ids: twistsExclIds,
    });
    console.info("Legacy battle settings migrated from cookie.");
  }

  console.info("Migrated 'battles' from v1.");
}

async function migrateFortuneFromV1(manager: Manager) {
  // There is a single 'fortune hand' per version of the game.
  const fortunes = manager.createObjectStore("fortunes", {
    keyPath: "ruleset_version",
  });

  // Prior to v2, playing cards were stored in a cookie
  const playingCardIds = getStringArrayFromCookie("fortune_hand");

  // If the user had some cards selected, create a legacy fortune card
  // hand in the database.
  if (isNonEmpty(playingCardIds)) {
    await fortunes.put({
      ruleset_version: "0.2.0",
      playing_cards_ids: playingCardIds,
    });

    console.info("Legacy fortunes migrated from cookie.");
  }

  console.info("Migrated 'fortunes' from v1.");
}

export async function migrateToV2(manager: Manager) {
  await migrateArmiesToV2(manager);
  await migrateBattlesToV2(manager);
  await migrateFortuneFromV1(manager);

  // Clean up cookies if everything else succeeded. We won't need them any more.
  removeCookie("scenario_exclusions");
  removeCookie("battlefield_exclusions");
  removeCookie("deployment_exclusions");
  removeCookie("twist_exclusions");
  removeCookie("fortune_hand");
}
