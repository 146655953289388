import { stringifyUnitType } from "@/domain/units";
import { FauxDialogLayout } from "../faux-dialog-layout";
import { useRuleset } from "../game-content-provider";
import * as tableCss from "../unit-profiles/style.css";
import { useObserveVisibility } from "../hooks/use-observe-visibility";

export function TargetNumbers() {
  const ruleset = useRuleset();
  const [ref, isFullyVisible] = useObserveVisibility<HTMLTableRowElement>({
    threshold: 1,
  });

  return (
    <FauxDialogLayout heading="Target Numbers" theme="dark">
      {!isFullyVisible && (
        <p className={tableCss.scrollHint}>Scroll horizontally to explore the table →</p>
      )}
      <div className={tableCss.container}>
        <table className={tableCss.table}>
          <thead>
            <tr ref={ref} className={tableCss.headerRow}>
              <th
                className={tableCss.cell({
                  align: "left",
                  heading: true,
                  sticky: true,
                })}
                style={{ width: "15%" }}
              >
                Unit Attacking
              </th>
              {ruleset.unitProfiles.map((unitProfile) => {
                return (
                  <th key={unitProfile.type} className={tableCss.cell({ heading: true })}>
                    {stringifyUnitType(unitProfile.type)}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {ruleset.unitProfiles.map((unitProfile) => {
              return (
                <tr key={unitProfile.type} className={tableCss.row}>
                  <th
                    className={tableCss.cell({
                      heading: true,
                      align: "left",
                      sticky: true,
                    })}
                  >
                    {stringifyUnitType(unitProfile.type)}
                  </th>

                  {unitProfile.targetScores.map((score) => {
                    return <td className={tableCss.cell()}>{score.minScore}+</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </FauxDialogLayout>
  );
}
