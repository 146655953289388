import { RESERVED_KEYWORDS_IDS } from "@/domain/keyword-types";
import { assert } from "@/lib/assert";
import { isOneOf } from "@/lib/typeguards";
import { SectionId, sectionIdToKey } from "@/lib/user-settings";
import { maybeAppendCopyToText } from "@indietabletop/appkit/append-copy-to-text";
import { ModernIDB } from "../../oss/modern-idb";
import {
  ArmyData,
  ArmyRecordData,
  ArmyTombstoneData,
  FortuneData,
  Indexes,
  Schema,
  SettingsData,
  UnitData,
} from "../../schema/latest";
import { id } from "../nanoid";
import dummyArmies from "./dummy-armies.json";
import { initDatabase } from "./init";
import { migrateDatabase } from "./migrations";

export class HobgoblinDatabase extends ModernIDB<Schema, Indexes> {
  constructor() {
    super({
      name: "hobgoblin",
      version: 5,
      onInit: initDatabase,
      onUpgrade: migrateDatabase,
    });
  }

  /**
   * Warning: This method does not emit change events on the DB instance!
   */
  async clearAll() {
    const [stores, complete] = this.transaction(["armies", "fortunes", "settings"], "readwrite", {
      noEmit: true,
    });

    await Promise.all([...stores.map((store) => store.clear()), complete]);
  }

  /**
   * Returns a set of rulesets used by non-deleted armies that currently exist
   * in IndexedDB.
   */
  async getArmiesRulesets() {
    const [armies, done] = this.transaction("armies");

    const rulesets = new Set<string>();
    for await (const cursor of armies.openCursor()) {
      const army = cursor.value;
      if (!army.deleted) {
        rulesets.add(army.ruleset_version);
      }
    }

    await done;
    return rulesets;
  }

  assertIsArmyData(army: ArmyRecordData | undefined, message: string): asserts army is ArmyData {
    assert(army && !army.deleted, message);
  }

  async writeSyncedArmies(armies: (ArmyData | ArmyTombstoneData)[]) {
    if (armies.length === 0) {
      return;
    }

    const [store, done] = this.transaction("armies", "readwrite");
    await Promise.all([...armies.map((army) => store.put(army)), done]);
  }

  async getUpdatedArmiesSince(sinceTs: number | null) {
    const [store, done] = this.transaction("armies");
    const updated = store.index("updated_ts_idx");

    // If a timestamp is provided, we use it as the lower bound, getting all
    // armies that were updated after the specified time. Otherwise we use an
    // unbounded range by using `null` (retrieves all items).
    const range = sinceTs && IDBKeyRange.lowerBound(sinceTs);

    const [updatedArmies] = await Promise.all([updated.getAll(range), done]);
    return updatedArmies;
  }

  async getArmies() {
    const [store, done] = this.transaction("armies");
    const index = store.index("created_ts_idx");
    const [data] = await Promise.all([index.getAll(), done]);

    return data.filter((a): a is ArmyData => !a.deleted);
  }

  async getArmy(armyId: string) {
    const armyOrTombstone = await this.getFromStore("armies", armyId);

    if (armyOrTombstone?.deleted) {
      return null;
    }

    return armyOrTombstone;
  }

  async deleteArmy(army: ArmyData) {
    const [store, done] = this.transaction("armies", "readwrite");

    await Promise.all([
      store.put({
        id: army.id,
        name: army.name,
        deleted: true,
        updated_ts: Date.now(),
      }),
      done,
    ]);
  }

  async updateArmy(armyData: ArmyData) {
    // Whenever we are updating an army, we remove the share link as a new
    // link will need to be generated.
    return this.putToStore("armies", {
      ...armyData,
      share_id: null,
      updated_ts: Date.now(),
    });
  }

  async setArmyShareId(armyId: string, shareId: string) {
    const [store, done] = this.transaction("armies", "readwrite");
    const army = await store.get(armyId);

    this.assertIsArmyData(
      army,
      `Could not set army's share id because army could not be retrieved (armyId: ${armyId}).`,
    );

    await Promise.all([store.put({ ...army, share_id: shareId, updated_ts: Date.now() }), done]);

    return armyId;
  }

  async addUnit(armyId: string, data: Pick<UnitData, "name" | "type" | "description">) {
    const [store, done] = this.transaction("armies", "readwrite");
    const army = await store.get(armyId);

    this.assertIsArmyData(
      army,
      `Could not add unit to army because army could not be retrieved (armyId: ${armyId}).`,
    );

    const unitId = id();

    await Promise.all([
      store.put({
        ...army,
        share_id: null,
        updated_ts: Date.now(),
        units: army.units.concat({
          id: unitId,
          name: data.name.trim(),
          type: data.type,
          description: data.description.trim(),
          keywords: [],
          count: 1,
        }),
      }),
      done,
    ]);

    return unitId;
  }

  async duplicateUnit(armyId: string, data: UnitData) {
    const [store, done] = this.transaction("armies", "readwrite");
    const army = await store.get(armyId);

    this.assertIsArmyData(
      army,
      `Could not duplicate unit because army could not be retrieve (armyId: ${armyId}).`,
    );

    const unitId = id();

    await Promise.all([
      store.put({
        ...army,
        share_id: null,
        updated_ts: Date.now(),
        units: army.units.concat({
          ...data,
          id: unitId,
          name: maybeAppendCopyToText(data.name),
          keywords: data.keywords.filter((keyword) => {
            // Strip out reserved keywords, as an army can only include one of each.
            return !RESERVED_KEYWORDS_IDS.includes(keyword.id);
          }),

          // Since the general keyword will be stripped from unit, any cursed artefacts
          // will also be removed.
          cursed_artefacts: [],
        }),
      }),
      done,
    ]);

    return unitId;
  }

  async createArmy(
    data: Pick<ArmyData, "name" | "description" | "ruleset_version"> &
      Pick<Partial<ArmyData>, "share_id" | "units" | "images">,
  ) {
    return this.putToStore("armies", {
      id: id(),
      name: data.name,
      description: data.description,
      share_id: data.share_id ?? null,
      created_ts: Date.now(),
      updated_ts: Date.now(),
      units: data.units ?? [],
      ruleset_version: data.ruleset_version,
      images: data.images ?? [],
      deleted: false,
    });
  }

  async duplicateArmy(data: ArmyData) {
    return this.putToStore("armies", {
      ...data,
      id: id(),
      name: maybeAppendCopyToText(data.name),
    });
  }

  async updateUnit(
    armyId: string,
    unitId: string,
    data: Partial<
      Pick<
        UnitData,
        | "name"
        | "description"
        | "type"
        | "keywords"
        | "count"
        | "abyssal_allegiances"
        | "cursed_artefacts"
      >
    >,
  ) {
    const [store, done] = this.transaction("armies", "readwrite");
    const army = await store.get(armyId);
    this.assertIsArmyData(
      army,
      `Could not update unit in army because army could not be retrieved (armyId: ${armyId}).`,
    );

    // Check whether a army-unique keywords were used
    const usedReservedKeywordsIds =
      data.keywords
        ?.filter((keyword) => RESERVED_KEYWORDS_IDS.includes(keyword.id))
        .map((keyword) => keyword.id) ?? [];
    const reservedKeywordsUsed = usedReservedKeywordsIds.length > 0;

    await Promise.all([
      store.put({
        ...army,
        share_id: null,
        updated_ts: Date.now(),
        units: army.units.map((unit) => {
          // Update the target unit
          if (unit.id === unitId) {
            return {
              ...unit,
              name: data.name?.trim() ?? unit.name,
              description: data.description?.trim() ?? unit.description,
              type: data.type ?? unit.type,

              // There can only be a single unit with a general or battle standard
              count: reservedKeywordsUsed ? 1 : (data.count ?? unit.count),
              abyssal_allegiances: data.abyssal_allegiances ?? unit.abyssal_allegiances,
              cursed_artefacts: data.cursed_artefacts ?? unit.cursed_artefacts,
              keywords: data.keywords ?? unit.keywords,
            };
          }

          // Update other units if reserved keywords were used.
          if (reservedKeywordsUsed) {
            const generalUsed = usedReservedKeywordsIds.includes("general");

            const keywords = unit.keywords.filter((keyword) => {
              return !isOneOf(keyword.id, usedReservedKeywordsIds);
            });

            return {
              ...unit,
              cursed_artefacts: generalUsed ? [] : unit.cursed_artefacts,
              keywords,
            };
          }

          // Otherwise don't touch other units
          return unit;
        }),
      }),
      done,
    ]);

    return unitId;
  }

  async removeUnit(armyId: string, unitId: string) {
    const [store, done] = this.transaction("armies", "readwrite");
    const army = await store.get(armyId);
    this.assertIsArmyData(
      army,
      `Could not remove unit from army because army could not be retrieved (armyId: ${armyId}).`,
    );

    await Promise.all([
      store.put({
        ...army,
        share_id: null,
        updated_ts: Date.now(),
        units: army.units.filter((unit) => unit.id !== unitId),
      }),
      done,
    ]);

    return unitId;
  }

  async createDummyArmies() {
    function jsonToArmy(dummyArmy: (typeof dummyArmies)[number]): ArmyData {
      const timestamp = Date.now();

      return {
        ...dummyArmy,
        id: id(),
        created_ts: timestamp,
        updated_ts: timestamp,
        deleted: false,
        share_id: null,
        images: dummyArmy.images as ArmyData["images"],
        units: dummyArmy.units.map((unit) => {
          return { ...unit, id: id() } as ArmyData["units"][number];
        }),
      };
    }

    const [store, done] = this.transaction("armies", "readwrite");

    await Promise.all([...dummyArmies.map((dummyArmy) => store.put(jsonToArmy(dummyArmy))), done]);
  }

  // USER SETTINGS

  async updateUserSettings(userSettings: SettingsData) {
    return this.putToStore("settings", userSettings);
  }

  async addEntryToExcludedItems(userSettings: SettingsData, sectionId: SectionId, entryId: string) {
    const key = sectionIdToKey[sectionId];

    const newUserSettings: SettingsData = {
      ...userSettings,
      [key]: [...userSettings[key], entryId],
    };

    return this.updateUserSettings(newUserSettings);
  }

  async removeEntryFromExcludedItems(
    userSettings: SettingsData,
    sectionId: SectionId,
    entryId: string,
  ) {
    const key = sectionIdToKey[sectionId];

    const newUserSettings: SettingsData = {
      ...userSettings,
      [key]: userSettings[key].filter((storedItemId) => storedItemId !== entryId),
    };

    return this.updateUserSettings(newUserSettings);
  }

  async resetExcludedItemsForSection(userSettings: SettingsData, sectionId: SectionId) {
    const key = sectionIdToKey[sectionId];

    const newUserSettings: SettingsData = {
      ...userSettings,
      [key]: [],
    };

    return this.updateUserSettings(newUserSettings);
  }

  /**
   * Will return the highest version of user settings or, if not found,
   * an empty user settings with the version supplied.
   */
  async getLatestUserSettings(fallbackVersion: string) {
    const [store, complete] = this.transaction("settings", "readonly");

    const { value: cursor } = await store.openCursor(null, "prev").next();

    const userSettings: SettingsData =
      cursor ?
        cursor.value
      : {
          ruleset_version: fallbackVersion,
          excluded_battlefields_ids: [],
          excluded_deployments_ids: [],
          excluded_scenarios_ids: [],
          excluded_twists_ids: [],
        };

    await complete;
    return userSettings;
  }

  async migrateUserSettings(newVersion: string, userSettings: SettingsData) {
    return this.putToStore("settings", {
      ...userSettings,
      ruleset_version: newVersion,
      excluded_deployments_ids: userSettings.excluded_deployments_ids.map((id) => {
        switch (id) {
          case "early_sighting": {
            return "early_warning";
          }

          case "surprise": {
            return "from_the_shadows";
          }

          case "surprise_engagement": {
            return "lost_in_the_dark";
          }

          default:
            return id;
        }
      }),
    });
  }

  // FORTUNE CARDS

  /**
   * This method always returns FortuneData. If they don't exist
   */
  async getFortuneHand(gameVersion: string) {
    const fortuneHand = await this.getFromStore("fortunes", gameVersion);

    return (
      fortuneHand ?? {
        ruleset_version: gameVersion,
        playing_cards_ids: [],
      }
    );
  }

  async updateFortuneHand(fortune: FortuneData) {
    return this.putToStore("fortunes", fortune);
  }

  async migrateFortuneCards(newVersion: string, fortune: FortuneData) {
    // Fortune Hand has no breaking changes, so we simply bump version number.
    return this.putToStore("fortunes", {
      ...fortune,
      ruleset_version: newVersion,
    });
  }

  // COMMON

  async getOutdatedItems(targetVersion: string) {
    const [[armiesStore, settingsStore], done] = this.transaction(["armies", "settings"]);

    const lessThanTargetVersion = IDBKeyRange.upperBound(targetVersion, true);

    // For Armies, we simply get all armies that are less than the supplied version
    const armiesData = await armiesStore.index("ruleset_version_idx").getAll(lessThanTargetVersion);

    // If an up-to-date version exists, there is no need to update. Otherwise,
    // attempt to fetch any lower version. If none exists, there is also
    // no need to update, as users will create a latest version the by using
    // any features that require settings.
    const upToDateUserSettings = await settingsStore.get(targetVersion);
    const userSettingsData =
      upToDateUserSettings ? undefined : await settingsStore.get(lessThanTargetVersion);

    await done;

    return {
      armiesData: armiesData.filter((a): a is ArmyData => !a.deleted),
      userSettingsData,
    };
  }
}
