import { AsyncOp, Pending } from "@indietabletop/appkit/async-op";
import { createContext, useContext } from "react";
import { Army } from "../../domain/army.js";

export const ArmyContext = createContext<AsyncOp<Army, { type: string; error?: string }>>(
  new Pending(),
);

export function useArmyOp() {
  return useContext(ArmyContext);
}
