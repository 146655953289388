import { config } from "@/config";
import { Ruleset, RulesetData } from "@/domain/ruleset";
import { AsyncOp, Failure, Pending, Success } from "@indietabletop/appkit/async-op";
import { caughtValueToString } from "@indietabletop/appkit/caught-value";
import { SemverResolver } from "./semver-resolver";
import { isEmpty } from "./typeguards";

export type VersionResolutionError = {
  type: string;
  message?: string;
};

type RulesetOp = AsyncOp<Ruleset, VersionResolutionError>;

const RESOLVED = "resolved";

type GameSystemEventDetail = {
  requestedVersion: string;
  rulesetOp: RulesetOp;
};

export class GameSystemEvent extends CustomEvent<GameSystemEventDetail> {
  constructor(detail: GameSystemEventDetail) {
    super(RESOLVED, { detail });
  }
}

export class GameSystem {
  public rulesets: SemverResolver<AsyncOp<Ruleset, VersionResolutionError>>;
  public emitter: EventTarget;
  public latestRuleset: Ruleset;

  constructor(rulesetsData: RulesetData[]) {
    this.emitter = new EventTarget();

    if (isEmpty(rulesetsData)) {
      throw new Error("At least one ruleset must be provided.");
    }

    const rulesets = rulesetsData.map((r) => new Ruleset(r));
    this.rulesets = new SemverResolver(rulesets.map((r) => [r.version, new Success(r)]));

    const latestRulesetOp = this.rulesets.latest;
    if (!latestRulesetOp?.isSuccess) {
      throw new Error("Latest ruleset must be resolved.");
    }

    this.latestRuleset = latestRulesetOp.value;
  }

  resolve(version: string) {
    const resolvedVersionOp = this.rulesets.resolve(version);

    // If ruleset is available locally, return that.
    if (resolvedVersionOp) {
      return resolvedVersionOp;
    }

    // Otherwise, request version from the server
    const pendingOp = new Pending();

    // Mark this version as pending to make sure duplicate requests are not made
    this.rulesets.add(version, pendingOp);

    // Attempt to resolve version from server side
    void this.resolveFromServer(version);

    return pendingOp;
  }

  async resolveFromServer(version: string) {
    const rulesetOp = await this.fetchRuleset(version);
    this.rulesets.add(version, rulesetOp);

    // Dispatch event to notify listeners
    this.emitter.dispatchEvent(new GameSystemEvent({ requestedVersion: version, rulesetOp }));
  }

  private async fetchRuleset(version: string) {
    let response;
    let payload;

    try {
      const path = `${config.ITC_API_ORIGIN}/hobgoblin/rulesets/${version}`;
      response = await fetch(path);
      payload = (await response.json()) as RulesetData;
    } catch (error) {
      return new Failure({
        type: "FETCH_FAILED",
        message: caughtValueToString(error),
      });
    }

    if (response.ok) {
      const ruleset = new Ruleset(payload);

      // If new ruleset is above the current latest, update the latestRuleset to
      // point to the new one.
      if (ruleset.version > this.latestRuleset.version) {
        this.latestRuleset = ruleset;
      }

      return new Success(ruleset);
    }

    return new Failure<VersionResolutionError>({
      type: "RULESET_NOT_FOUND",
      message: `Ruleset version ${version} could not be resolved.`,
    });
  }

  subscribe(callback: (e: GameSystemEvent) => void) {
    this.emitter.addEventListener(RESOLVED, callback as EventListener);
  }

  unsubscribe(callback: (e: GameSystemEvent) => void) {
    this.emitter.removeEventListener(RESOLVED, callback as EventListener);
  }
}
