import { replaceKeywordValue } from "@/domain/keyword-types";
import { stringifyFootprint, stringifyUnitType } from "@/domain/units";
import { numericalStat } from "@/lib/numerical-stat";
import { Fragment } from "react/jsx-runtime";
import { FauxDialogLayout } from "../faux-dialog-layout";
import { useRuleset } from "../game-content-provider";
import { useKeywords } from "../keywords-handler";
import * as css from "./style.css";
import { useObserveVisibility } from "../hooks/use-observe-visibility";

export function UnitProfiles() {
  const ruleset = useRuleset();
  const peekKeyword = useKeywords();
  const [ref, isFullyVisible] = useObserveVisibility<HTMLTableRowElement>({
    threshold: 1,
  });

  return (
    <FauxDialogLayout heading="Unit Profiles" theme="dark">
      {!isFullyVisible && (
        <p className={css.scrollHint}>Scroll horizontally to explore the table →</p>
      )}

      <div className={css.container}>
        <table className={css.table}>
          <thead>
            <tr ref={ref} className={css.headerRow}>
              <th
                className={css.cell({
                  align: "left",
                  heading: true,
                  sticky: true,
                })}
                style={{ width: "14%" }}
              >
                Unit Type
              </th>
              <th className={css.cell({ heading: true })}>Speed</th>
              <th className={css.cell({ heading: true })}>Range</th>
              <th className={css.cell({ heading: true })}>Strikes</th>
              <th className={css.cell({ heading: true })}>Courage</th>
              <th className={css.cell({ heading: true })}>Footprint</th>
              <th className={css.cell({ heading: true })}>Multiplier</th>
              <th className={css.cell({ heading: true })}>Points</th>
              <th className={css.cell({ align: "left", heading: true })} style={{ width: "24%" }}>
                Keywords
              </th>
            </tr>
          </thead>

          <tbody>
            {ruleset.unitProfiles.map((unitProfile) => {
              return (
                <tr key={unitProfile.type} className={css.row}>
                  <td className={css.cell({ align: "left", sticky: true })}>
                    {stringifyUnitType(unitProfile.type)}
                  </td>
                  <td className={css.cell()}>{unitProfile.speed}</td>
                  <td className={css.cell()}>{numericalStat(unitProfile.range, "″")}</td>
                  <td className={css.cell()}>{unitProfile.strikes}</td>
                  <td className={css.cell()}>{unitProfile.courage}</td>
                  <td className={css.cell()}>{stringifyFootprint(unitProfile.footprint)}</td>
                  <td className={css.cell()}>{unitProfile.keywordsCostMultiplier}x</td>
                  <td className={css.cell()}>{unitProfile.points}pts</td>
                  <td className={css.cell({ align: "left" })}>
                    {unitProfile.defaultKeywords.map((kw, i) => {
                      const keyword = ruleset.keywordsDefsById.get(kw.id);
                      if (!keyword) {
                        return null;
                      }

                      const keywordName =
                        kw.numerical ?
                          replaceKeywordValue(keyword.name, kw.numerical.value)
                        : keyword.name;

                      return (
                        <Fragment key={kw.id}>
                          {i !== 0 ? ", " : null}

                          <button
                            className={css.keyword}
                            type="button"
                            onClick={() => {
                              peekKeyword({ id: kw.id });
                            }}
                          >
                            {keywordName}
                          </button>
                        </Fragment>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </FauxDialogLayout>
  );
}
