import { Form, useStoreState } from "@ariakit/react";
import { Dispatch, SetStateAction, useState } from "react";
import { client } from "../client";
import { FormSubmitButton } from "../form-submit-button";
import { FormSubmitError } from "../form-submit-error";
import { toKnownFailureMessage, useForm, validEmail, validPassword } from "../hooks/use-form";
import { IndieTabletopClubFooter, IndieTabletopClubSymbol } from "../indie-tabletop-club-brand";
import { FormTextField } from "../inputs";
import { InputsGroup } from "../inputs/group";
import { Link } from "../link";
import { getHistoryState } from "../link/history-state";
import { ViewContainer } from "../max-width-container";
import { MobileNavLayout } from "../mobile-nav-layout";
import { useCurrentUser } from "../store/hooks";
import {
  button,
  formFooter,
  formHeader,
  formSubmitArea,
  heading,
  interactiveText,
  paragraph,
  primaryTheme,
  section,
} from "../utils.css";

type SetStep = Dispatch<SetStateAction<Steps>>;

function RequestPasswordResetStep(props: { setStep: SetStep }) {
  const currentUserEmail = useCurrentUser()?.email;
  const historyStateEmail = getHistoryState()?.emailValue;
  const initialEmailValue = currentUserEmail ?? historyStateEmail ?? "";

  const { form, submitName } = useForm({
    defaultValues: {
      email: initialEmailValue,
    },
    validate: {
      email: validEmail,
    },

    async onSubmit({ values }) {
      const op = await client.requestPasswordReset(values);

      return op.mapFailure((failure) => {
        if (failure.type === "API_ERROR" && failure.code === 404) {
          return "Could not find a user with this email.";
        }

        return toKnownFailureMessage(failure);
      });
    },

    onSuccess(value, { values }) {
      props.setStep({
        type: "SUBMIT_CODE",
        tokenId: value.tokenId,
        email: values.email,
      });
    },
  });

  const emailValue = useStoreState(form, (s) => s.values.email);

  return (
    <div>
      <Form store={form}>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />

          <h1 className={heading}>Password reset</h1>
          <p className={paragraph}>
            Enter your Indie Tabletop Club account email address to begin password reset. We will
            send you a one-time code to verify your access.
          </p>
        </div>
        <InputsGroup>
          <FormTextField
            name={form.names.email}
            label="Email"
            type="email"
            placeholder="E.g. guruk@rotvarlden.com"
            required
          />

          <FormSubmitError name={submitName} />
        </InputsGroup>

        <div className={formSubmitArea}>
          <FormSubmitButton label="Continue" />
        </div>
      </Form>
      <footer className={formFooter}>
        <p className={paragraph}>
          {"Remembered your password? "}
          <Link href="/login" className={interactiveText} state={{ emailValue }}>
            Log in.
          </Link>
        </p>
      </footer>

      <IndieTabletopClubFooter />
    </div>
  );
}

function SubmitCodeStep(props: { tokenId: string; email: string; setStep: SetStep }) {
  const { form, submitName } = useForm({
    defaultValues: {
      code: "",
    },

    async onSubmit({ values }) {
      const op = await client.checkPasswordResetCode({
        ...values,
        tokenId: props.tokenId,
      });

      return op.mapFailure((failure) => {
        if (failure.type === "API_ERROR" && failure.code === 404) {
          return "This code is incorrect or expired. Please try again.";
        }

        return toKnownFailureMessage(failure);
      });
    },

    onSuccess(_, { values }) {
      props.setStep({
        type: "SET_NEW_PASSWORD",
        tokenId: props.tokenId,
        code: values.code,
        email: props.email,
      });
    },
  });

  return (
    <div>
      <Form store={form}>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />
          <h1 className={heading}>Submit Code</h1>
          <p className={paragraph}>
            We've sent a one-time code to the email address you have provided. Please, enter the
            code in the field below to continue.
          </p>
        </div>

        <InputsGroup>
          <FormTextField
            name={form.names.code}
            label="Code"
            placeholder="E.g. 123123"
            autoComplete="one-time-code"
            required
          />

          <FormSubmitError name={submitName} />
        </InputsGroup>

        <div className={formSubmitArea}>
          <FormSubmitButton label="Verify code" />
        </div>

        <IndieTabletopClubFooter />
      </Form>
    </div>
  );
}

function SetNewPasswordStep(props: {
  tokenId: string;
  code: string;
  email: string;
  setStep: SetStep;
}) {
  const { form, submitName } = useForm({
    defaultValues: { password: "" },
    validate: { password: validPassword },
    async onSubmit({ values }) {
      const res = await client.setNewPassword({
        tokenId: props.tokenId,
        code: props.code,
        password: values.password,
      });

      if (res.isFailure) {
        return res.mapFailure((failure) => {
          if (failure.type === "API_ERROR" && failure.code === 404) {
            return "One-time code has expired. Please restart the password reset process.";
          }

          return toKnownFailureMessage(failure);
        });
      }

      // Login attempt with new credentials. Must be performed in onSubmit so
      // that errors are correctly propagated.
      const loginOp = await client.login({
        email: props.email,
        password: values.password,
      });

      return loginOp.mapFailure(toKnownFailureMessage);
    },
    onSuccess() {
      props.setStep({ type: "SUCCESS" });
    },
  });

  return (
    <div>
      <Form store={form}>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />
          <h1 className={heading}>Choose New Password</h1>
          <p className={paragraph}>
            Please choose a new password. Make it at least 8 characters long.
          </p>
        </div>

        <InputsGroup>
          <FormTextField
            name={form.names.password}
            label="New Password"
            type="password"
            placeholder="Enter a new password"
            required
          />

          <FormSubmitError name={submitName} />
        </InputsGroup>

        <div className={formSubmitArea}>
          <FormSubmitButton label="Save & Login" />
        </div>
        <IndieTabletopClubFooter />
      </Form>
    </div>
  );
}

function SuccessStep() {
  return (
    <div>
      <div className={formHeader}>
        <IndieTabletopClubSymbol />
        <h1 className={heading}>Success!</h1>
        <p className={paragraph}>
          Your password has been successfully reset and you've been automatically logged in. Enjoy
          using the Hobgoblin App!
        </p>
      </div>

      <p className={paragraph}>
        <Link href="~/" className={button} style={primaryTheme}>
          Go to dashboard
        </Link>
      </p>
      <IndieTabletopClubFooter />
    </div>
  );
}

type Steps =
  | { type: "REQUEST_PASSWORD_RESET" }
  | { type: "SUBMIT_CODE"; tokenId: string; email: string }
  | { type: "SET_NEW_PASSWORD"; tokenId: string; code: string; email: string }
  | { type: "SUCCESS" };

export function ResetPasswordMain() {
  const [step, setStep] = useState<Steps>({ type: "REQUEST_PASSWORD_RESET" });

  switch (step.type) {
    case "REQUEST_PASSWORD_RESET": {
      return <RequestPasswordResetStep setStep={setStep} />;
    }

    case "SUBMIT_CODE": {
      return <SubmitCodeStep {...step} setStep={setStep} />;
    }

    case "SET_NEW_PASSWORD": {
      return <SetNewPasswordStep {...step} setStep={setStep} />;
    }

    case "SUCCESS": {
      return <SuccessStep />;
    }
  }
}

export function ResetPasswordPage() {
  return (
    <MobileNavLayout title="Login">
      <ViewContainer padding="small">
        <div className={section}>
          <ResetPasswordMain />
        </div>
      </ViewContainer>
    </MobileNavLayout>
  );
}
