import { Button } from "@/ui/button";
import { FormDialog } from "@/ui/form-dialog";
import { ReadonlyTextField } from "@/ui/inputs";
import { Paragraphs } from "@/ui/paragraphs";
import { button, paragraph } from "@/ui/utils.css";
import { DialogStore } from "@ariakit/react";
import { useRevertingState } from "@indietabletop/appkit/use-reverting-state";
import { QRCode } from "../qr-code";

function CopyToClipboardButton(props: { value: string | null; label: string }) {
  const [copiedTimestamp, setCopiedTimestamp] = useRevertingState<number | null>(null, 1500);

  return (
    <Button
      disabled={!props.value}
      className={button}
      onClick={async () => {
        if (props.value) {
          await navigator.clipboard.writeText(props.value);
          setCopiedTimestamp(Date.now());
        }
      }}
    >
      {copiedTimestamp ? "Copied!" : props.label}
    </Button>
  );
}

function WebShareButton(props: {
  shareData: { title: string; url: string } | null;
  label: string;
}) {
  const [copiedTimestamp, setCopiedTimestamp] = useRevertingState<number | null>(null, 1500);

  return (
    <Button
      disabled={!props.shareData}
      className={button}
      onClick={async () => {
        if (props.shareData) {
          try {
            await navigator.share(props.shareData);
            setCopiedTimestamp(Date.now());
          } catch {
            console.info("Not shared");
          }
        }
      }}
    >
      {copiedTimestamp ? "Shared!" : props.label}
    </Button>
  );
}

export function ShareButton(props: { shareData: { title: string; url: string } | null }) {
  if (!navigator.share) {
    return <CopyToClipboardButton value={props.shareData?.url ?? null} label="Copy share link" />;
  }

  return <WebShareButton shareData={props.shareData} label="Share link" />;
}

export function ShareDialog(props: {
  inputValue: string;
  shareData: { title: string; url: string } | null;
  heading: string;
  text: string;
  store: DialogStore;
}) {
  return (
    <FormDialog store={props.store} heading={props.heading} submit="Done">
      <div style={{ marginBottom: "2.5rem" }}>
        <Paragraphs text={props.text} />
      </div>

      <div style={{ display: "grid", gap: "0.75rem" }}>
        <ReadonlyTextField value={props.inputValue} label="Share Link" />
        <ShareButton {...props} />
      </div>

      <div
        style={{
          fontSize: "1rem",
          fontStyle: "italic",
          textAlign: "center",
          margin: "2rem 0",
        }}
      >
        — or —
      </div>

      <div
        style={{
          backgroundColor: "hsl(0 0% 0% / 0.05)",
          borderRadius: "0.5rem",
          textAlign: "center",
          padding: "2rem",
        }}
      >
        {props.shareData && <QRCode url={props.shareData.url} />}

        <p
          className={paragraph}
          style={{
            maxWidth: "20rem",
            textWrap: "balance",
            margin: "0 auto",
            fontStyle: "italic",
          }}
        >
          Let others scan this QR code with a camera to share in person.
        </p>
      </div>
    </FormDialog>
  );
}
