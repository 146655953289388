import { Army } from "@/domain/army";
import { ArmyData } from "@/schema/latest";
import { AsyncOp, Failure, fold, Success } from "@indietabletop/appkit/async-op";
import { ReactNode } from "react";
import { useResolveVersion } from "../game-content-provider/index.js";
import { ArmyContext } from "./context.js";

export function ArmyProvider(props: {
  armyDataOp: AsyncOp<ArmyData | null, { type: string; message?: string }>;
  children: ReactNode;
}) {
  const armyDataOp = props.armyDataOp.flatMap((armyData) => {
    return armyData ?
        new Success(armyData)
      : new Failure({
          type: "ARMY_NOT_FOUND",
          message: "Army with the specified ID not found.",
        });
  });
  const rulesetVersionOp = armyDataOp.mapSuccess((r) => r.ruleset_version);
  const rulesetOp = useResolveVersion(rulesetVersionOp.valueOrNull());
  const armyOp = fold([armyDataOp, rulesetOp]).mapSuccess(
    ([armyData, ruleset]) => new Army(ruleset, armyData),
  );

  return <ArmyContext.Provider value={armyOp}>{props.children}</ArmyContext.Provider>;
}
