import { Button } from "@/ui/button";
import { button, dangerTheme } from "@/ui/utils.css";
import { DialogDismiss, DialogStore } from "@ariakit/react";
import { ModalDialog } from "../modal-dialog";
import * as css from "./style.css";
import { ReactNode } from "react";

export function ConfirmDialog(props: {
  heading: string;
  message: ReactNode;
  submit: string;
  submitTheme?: Record<string, string>;
  onSubmit: () => Promise<void>;
  store: DialogStore;
}) {
  return (
    <ModalDialog store={props.store} className={css.dialog}>
      <form
        className={css.form}
        onSubmit={async (event) => {
          event.preventDefault();

          try {
            await props.onSubmit();
            props.store.hide();
          } catch (error) {
            console.error(error);
          }
        }}
      >
        <h1 className={css.heading}>{props.heading}</h1>
        <p className={css.message}>{props.message}</p>

        <div className={css.buttons}>
          <DialogDismiss className={button}>Cancel</DialogDismiss>

          <Button className={button} style={props.submitTheme ?? dangerTheme} type="submit">
            {props.submit}
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
}
