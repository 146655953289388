import { Army } from "@/domain/army";
import { Button } from "@/ui/button";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDatabase } from "@/ui/database-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useNavigate } from "@/ui/hooks/use-navigate";
import { button, primaryTheme } from "@/ui/utils.css";
import { DialogStore } from "@ariakit/react";
import { useParams } from "wouter";
import { ArmyDetailView, ArmyPageLayout } from "../army-detail-page";
import { ArmyProvider } from "../army-provider";
import { useArmyOp } from "../army-provider/context";
import { UnitDetailView } from "../unit-detail-page";
import { useFetchSharedArmy } from "./useFetchSharedArmy";

function DuplicateArmyDialog(props: { store: DialogStore; army: Army }) {
  const navigate = useNavigate();
  const database = useDatabase();

  return (
    <ConfirmDialog
      store={props.store}
      heading="Duplicate Army?"
      message={`${props.army.name} will be copied over to your armies.`}
      submit="Duplicate"
      submitTheme={primaryTheme}
      onSubmit={async () => {
        const armyId = await database.createArmy(props.army.data);
        navigate(`~/army/a/${armyId}`);
      }}
    />
  );
}

function DuplicateArmyButton() {
  const armyOp = useArmyOp();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && armyOp.isSuccess && <DuplicateArmyDialog store={store} army={armyOp.value} />}
      <Button className={button} onClick={setOpen} style={primaryTheme}>
        Duplicate Army
      </Button>
    </>
  );
}

export function SharedArmyPages() {
  const params = useParams<{ shareId: string }>();
  const result = useFetchSharedArmy(params.shareId);

  return (
    <ArmyProvider armyDataOp={result}>
      <ArmyPageLayout
        armyTitle="Shared Army"
        armyDetail={
          <ArmyDetailView headerLabel={"Shared Army"} stickyButton={<DuplicateArmyButton />} />
        }
        unitTitle="Shared Unit"
        unitDetail={<UnitDetailView headerLabel="Shared Unit" readOnly />}
      />
    </ArmyProvider>
  );
}
