import { config } from "@/config";
import { Army } from "@/domain/army";
import { stringifyFootprint, stringifyUnitType } from "@/domain/units";
import { numericalStat } from "@/lib/numerical-stat";
import { useArmyOp } from "@/ui/army-provider/context";
import { UnitMiniIcons } from "@/ui/mini-icon";
import { AsyncOp } from "@indietabletop/appkit/async-op";
import { Fragment } from "react";
import { KnownErrorMessage } from "../db-error";
import { useEnsureShareLink } from "../hooks/use-ensure-share-link";
import { createUsePersistedState } from "../hooks/use-persisted-state";
import { Image } from "../image";
import { Checkbox } from "../inputs/checkbox";
import { Link } from "../link";
import { LoadingIndicator } from "../loading-indicator";
import { LocalArmyProvider } from "../local-army-provider";
import { Paragraphs } from "../paragraphs";
import { button, interactiveText, paragraph, primaryTheme } from "../utils.css";
import * as css from "./style.css";

export function UnitCount(props: { count: number }) {
  if (props.count > 1) {
    return <span style={{ color: "hsl(0 0% 50%)" }}> x{props.count}</span>;
  }

  return null;
}

function PrintArmyView(props: {
  army: Army;
  shareLinkOp: AsyncOp<string, string>;
  settings: {
    includeArmyDescription: boolean;
    includeKeywords: boolean;
    includeAbyssalAllegiances: boolean;
    includeCursedArtefacts: boolean;
  };
}) {
  const { army, shareLinkOp, settings } = props;

  if (shareLinkOp.isPending) {
    return (
      <div className={css.prepMessageContainer}>
        <LoadingIndicator />
        <p>Preparing for print</p>
      </div>
    );
  }

  if (shareLinkOp.isFailure) {
    return (
      <div className={css.prepMessageContainer}>
        <p>Failed to prepare army for print. {shareLinkOp.failure}</p>
      </div>
    );
  }

  const shareLink = shareLinkOp.value;

  return (
    <main className={css.printContainer}>
      <hgroup className={css.hGroup}>
        <div>
          <h1 className={css.title}>{army.name}</h1>
          <p className={css.armySummary}>
            {army.summary} · {army.ruleset.versionName}
          </p>

          {settings.includeArmyDescription && army.description && (
            <div className={css.armyDescription}>
              <Paragraphs text={army.description} className={css.armyDescriptionParagraph} />
            </div>
          )}
        </div>

        {shareLinkOp && (
          <div>
            <img
              src={`${config.ITC_API_ORIGIN}/qr?${new URLSearchParams({ url: shareLink })}`}
              alt=""
              width="64"
              height="64"
              style={{
                width: "5rem",
                height: "auto",
                aspectRatio: "1",
                borderRadius: "0.5rem",
                border: "1px solid hsl(0 0% 50%)",
              }}
            />
            <p
              style={{
                fontStyle: "italic",
                textAlign: "center",
                fontSize: "0.5em",
                marginTop: "1em",
              }}
            >
              Scan to load army in
              <br />
              Hobgoblin app
            </p>
          </div>
        )}
      </hgroup>

      <section className={css.section}>
        <h2 className={css.sectionHeading}>Units</h2>
        <div className={css.unitsTable}>
          <div className={css.columnHeading}>Name</div>
          <div className={css.centeredColumnHeading}>Type</div>
          <div className={css.centeredColumnHeading}>Speed</div>
          <div className={css.centeredColumnHeading}>Range</div>
          <div className={css.centeredColumnHeading}>Strikes</div>
          <div className={css.centeredColumnHeading}>Courage</div>
          <div className={css.centeredColumnHeading}>Footprint</div>
          <div className={css.centeredColumnHeading}>Points</div>

          <div style={{ gridColumn: "1/-1", borderTop: "1px solid gray" }} />

          {army.units.map((unit) => {
            return (
              <Fragment key={unit.id}>
                <div className={css.unitNameCell}>
                  <span className={css.unitName}>{unit.name}</span>
                  <UnitCount count={unit.count} />
                  <UnitMiniIcons keywords={unit.keywords} />
                  {unit.keywordsSummary && (
                    <div className={css.unitKeywords}>{unit.keywordsSummary}</div>
                  )}
                </div>
                <div className={css.tableCellText}>{stringifyUnitType(unit.type)}</div>
                <div className={css.tableCell}>{numericalStat(unit.speed)}</div>
                <div className={css.tableCell}>{numericalStat(unit.range, "″")}</div>
                <div className={css.tableCell}>{unit.strikes}</div>
                <div className={css.tableCell}>{numericalStat(unit.courage)}</div>
                <div className={css.tableCellText}>{stringifyFootprint(unit.footprint)}</div>
                <div className={css.tableCell}>{unit.points}</div>
              </Fragment>
            );
          })}
        </div>
      </section>

      {settings.includeKeywords && (
        <>
          <h2 className={css.sectionHeading}>Keywords</h2>

          <div className={css.twoColumnSection}>
            {army.keywordsDefinitions.map((keyword) => {
              return (
                <div key={keyword.id} className={css.keywordDefinition}>
                  <h3 className={css.entryHeading}>{keyword.name}</h3>
                  <div
                    className={css.richText}
                    dangerouslySetInnerHTML={{
                      __html: keyword.descriptionWithTraits,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}

      {settings.includeAbyssalAllegiances && army.abyssalAllegiancesDefinitions.length > 0 && (
        <>
          <h2 className={css.sectionHeading}>Abyssal Allegiances</h2>

          <div className={css.twoColumnSection}>
            {army.abyssalAllegiancesDefinitions.map((allegiance) => {
              return (
                <div key={allegiance.id} className={css.keywordDefinition}>
                  <h3 className={css.entryHeading}>{allegiance.name}</h3>

                  {allegiance.spells.map((spell) => {
                    return (
                      <div key={spell.name} style={{ marginTop: "1em" }}>
                        <h4 style={{ fontWeight: 600 }}>{spell.name}</h4>
                        <div
                          className={css.richText}
                          dangerouslySetInnerHTML={{
                            __html: spell.descriptionWithType,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}

      {settings.includeCursedArtefacts && army.cursedArtefactsDefinitions.length > 0 && (
        <>
          <h2 className={css.sectionHeading}>Cursed Artefacts</h2>

          <div className={css.twoColumnSection}>
            {army.cursedArtefactsDefinitions.map((artefact) => {
              return (
                <div key={artefact.id} className={css.keywordDefinition}>
                  <h3 className={css.entryHeading}>{artefact.name}</h3>
                  <p>
                    This artefact conveys the following keywords onto this unit:{" "}
                    {artefact.keywordsSummary}
                  </p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </main>
  );
}

const usePrintSettings = createUsePersistedState(
  {
    include_army_description: true,
    include_keywords: true,
    include_abyssal_allegiances: true,
    include_cursed_artefacts: false,
  },
  { namespace: "print:" },
);

function PrintArmyContent(props: { army: Army }) {
  const [includeArmyDescription, setIncludeArmyDescription] = usePrintSettings(
    "include_army_description",
  );
  const [includeKeywords, setIncludeKeywords] = usePrintSettings("include_keywords");
  const [includeAbyssalAllegiances, setIncludeAbyssalAllegiances] = usePrintSettings(
    "include_abyssal_allegiances",
  );
  const [includeCursedArtefacts, setIncludeCursedArtefacts] = usePrintSettings(
    "include_cursed_artefacts",
  );

  const shareLinkOp = useEnsureShareLink(props.army.data);

  return (
    <div className={css.printPage}>
      <div className={css.printPagePreview}>
        <PrintArmyView
          {...props}
          shareLinkOp={shareLinkOp}
          settings={{
            includeArmyDescription,
            includeKeywords,
            includeAbyssalAllegiances,
            includeCursedArtefacts,
          }}
        />
      </div>

      <div className={css.printControls}>
        <div className={css.printNav}>
          <div className={css.printNavLabel}>Print</div>

          <Link href={`/army/a/${props.army.id}`}>
            <Image src="/icons/cross.svg" alt="Back" width={40} height={40} />
          </Link>
        </div>

        <div className={css.printControlsContent}>
          <section className={css.controlsSection}>
            <p className={paragraph}>
              To get the best results when printing, check out the{" "}
              <Link className={interactiveText} href="/help/printing-tips">
                printing tips & tricks
              </Link>
              .
            </p>
          </section>

          <section className={css.controlsSection}>
            <h2 className={css.controlsHeading}>Sections</h2>
            <p>Which sections do you want to print?</p>

            <div className={css.checkboxes}>
              <Checkbox
                label="Army description"
                name="include_army_description"
                checked={includeArmyDescription}
                onChecked={() => setIncludeArmyDescription(true)}
                onUnchecked={() => setIncludeArmyDescription(false)}
              />
              <Checkbox
                label="Keywords"
                name="include_keywords"
                checked={includeKeywords}
                onChecked={() => setIncludeKeywords(true)}
                onUnchecked={() => setIncludeKeywords(false)}
              />
              <Checkbox
                label="Abyssal allegiances"
                name="include_abyssal_allegiances"
                checked={includeAbyssalAllegiances}
                onChecked={() => setIncludeAbyssalAllegiances(true)}
                onUnchecked={() => setIncludeAbyssalAllegiances(false)}
              />
              <Checkbox
                label="Cursed artefacts"
                name="include_cursed_artefacts"
                checked={includeCursedArtefacts}
                onChecked={() => setIncludeCursedArtefacts(true)}
                onUnchecked={() => setIncludeCursedArtefacts(false)}
              />
            </div>
          </section>

          <div className={css.buttonContainer}>
            <button className={button} style={primaryTheme} onClick={() => window.print()}>
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PrintArmyMain() {
  const armyOp = useArmyOp();

  if (armyOp.isPending) {
    return <LoadingIndicator />;
  }

  if (armyOp.isFailure) {
    return <KnownErrorMessage error={armyOp.failure} />;
  }

  return <PrintArmyContent army={armyOp.value} />;
}

export function PrintArmyPage(props: { params: { armyId: string } }) {
  return (
    <LocalArmyProvider armyId={props.params.armyId}>
      <PrintArmyMain />
    </LocalArmyProvider>
  );
}
