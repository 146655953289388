import { VersionChangeManager } from "@/oss/modern-idb";
import { isString } from "../typeguards";
import { IndexesV2, KeywordRefDataV2, SchemaV2 } from "../../schema/schema-v2";
import { SchemaV3, KeywordRefDataV3 } from "../../schema/schema-v3";

type Manager = VersionChangeManager<SchemaV3 | SchemaV2, IndexesV2>;
function findArtefactIdInKeywords(keywords: KeywordRefDataV2[] | KeywordRefDataV3[]) {
  for (const keyword of keywords) {
    if ("artefact_id" in keyword && isString(keyword.artefact_id)) {
      return keyword.artefact_id;
    }
  }
}

export async function migrateToV3(manager: Manager) {
  const store = manager.transaction.objectStore("armies");

  // In v2, artefact ids were stored in keywords instead of units.
  for await (const cursor of store.openCursor()) {
    const army = cursor.value;

    void cursor.update({
      ...army,
      units: army.units.map((unit) => {
        const artefactId = findArtefactIdInKeywords(unit.keywords);

        return {
          ...unit,
          cursed_artefacts: artefactId ? [{ id: artefactId }] : [],
          keywords: unit.keywords.map((keyword) => {
            return {
              id: keyword.id,
              numerical: keyword.numerical,
            };
          }),
        };
      }),
    });
  }

  console.info("Migrated 'armies' from v2.");
}
