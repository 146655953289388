import { Army } from "@/domain/army";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDatabase } from "@/ui/database-provider";
import { useGameContentLookup } from "@/ui/game-content-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useNavigate } from "@/ui/hooks/use-navigate";
import {
  MoreMenu,
  MoreMenuDialogDisclosureItem,
  MoreMenuItem,
  MoreMenuLinkItem,
  MoreMenuSeparator,
} from "@/ui/more-menu";
import { ShareArmyDialog } from "@/ui/share-army-dialog";
import { UpgradeArmyRulesetDialog } from "@/ui/upgrade-army-ruleset-dialog";
import { primaryTheme } from "@/ui/utils.css";
import { EditArmyDialog } from "../army-form-dialog";
import { useArmyOp } from "../army-provider/context";
import { DialogTrigger } from "../dialog-trigger";

function UpdateArmyRulesetMenuItem(props: { army: Army }) {
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && <UpgradeArmyRulesetDialog store={store} army={props.army} />}
      <MoreMenuItem onClick={setOpen}>Upgrade to latest</MoreMenuItem>
    </>
  );
}

function EditArmyMenuItem(props: { army: Army }) {
  return (
    <DialogTrigger>
      <EditArmyDialog army={props.army} />
      <MoreMenuDialogDisclosureItem>Edit</MoreMenuDialogDisclosureItem>
    </DialogTrigger>
  );
}

function DuplicateArmyMenuItem(props: { army: Army }) {
  const database = useDatabase();
  const navigate = useNavigate();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          store={store}
          heading="Duplicate Army?"
          message={`${props.army.name} with all its units will be duplicated.`}
          submit="Duplicate"
          submitTheme={primaryTheme}
          onSubmit={async () => {
            const armyId = await database.duplicateArmy(props.army.data);
            navigate(`~/army/a/${armyId}`);
          }}
        />
      )}

      <MoreMenuItem onClick={setOpen}>Duplicate</MoreMenuItem>
    </>
  );
}

function ShareArmyMenuItem(props: { army: Army }) {
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ShareArmyDialog
          store={store}
          armyData={props.army.data}
          armyShareTitle={`${props.army.name} (${props.army.summary})`}
        />
      )}

      <MoreMenuItem onClick={setOpen}>Share</MoreMenuItem>
    </>
  );
}

function DeleteArmyMenuItem(props: { army: Army }) {
  const database = useDatabase();
  const navigate = useNavigate();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          heading="Delete army?"
          message={`Are you sure you want to delete "${props.army.name}"?`}
          store={store}
          onSubmit={async () => {
            await database.deleteArmy(props.army.data);
            navigate(`~/army`);
          }}
          submit="Delete"
        />
      )}

      <MoreMenuItem onClick={setOpen}>Delete</MoreMenuItem>
    </>
  );
}

export function ArmyActionsMenu(props: { iconColor?: string }) {
  const armyOp = useArmyOp();
  const { latestRuleset } = useGameContentLookup();

  if (!armyOp.isSuccess) {
    return null;
  }

  const army = armyOp.value;
  const isOutdated = army.ruleset.version < latestRuleset.version;

  return (
    <MoreMenu fallbackLabel="Army actions" iconColor={props.iconColor}>
      {isOutdated && <UpdateArmyRulesetMenuItem army={army} />}
      <EditArmyMenuItem army={army} />
      <DuplicateArmyMenuItem army={army} />
      <ShareArmyMenuItem army={army} />
      <MoreMenuLinkItem href={`~/print/a/${army.id}`}>Print</MoreMenuLinkItem>

      <MoreMenuSeparator />

      <DeleteArmyMenuItem army={army} />
    </MoreMenu>
  );
}
