import { Unit } from "./unit.js";
import { SpellData, Spell } from "./spell.js";

export type AbyssalAllegianceDefinitionData = {
  id: string;
  name: string;
  flavour: string;
  spells: SpellData[];
};

export class AbyssalAllegianceDefinition {
  id: string;
  name: string;
  flavour: string;
  spells: Spell[];

  constructor(data: AbyssalAllegianceDefinitionData) {
    this.id = data.id;
    this.name = data.name;
    this.flavour = data.flavour;
    this.spells = data.spells.map((spellData) => new Spell(spellData));
  }
}

export type AbyssalAllegianceRef = {
  id: string;
};

export class AbyssalAllegiance {
  id: string;
  name: string;
  definition: AbyssalAllegianceDefinition;
  unit: Unit;

  constructor(unit: Unit, def: AbyssalAllegianceDefinition) {
    this.id = def.id;
    this.name = def.name;
    this.definition = def;
    this.unit = unit;
  }

  static fromRef(unit: Unit, ref: AbyssalAllegianceRef) {
    return new AbyssalAllegiance(unit, unit.ruleset.abyssalAllegiancesById.getOrThrow(ref.id));
  }
}
