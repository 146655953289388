import { LogItem, PersistedStoreContext, STORE_KEY, STORE_VERSION, StoreContext } from ".";

function maybeGetItem<T>(key: string, fallback: T) {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value) as T;
  }

  return fallback;
}

/**
 * Returns persisted store context with all data set to their initial values.
 */
function getFallbackPersistedContext(): PersistedStoreContext {
  return {
    version: STORE_VERSION,
    currentUser: null,
    sessionInfo: null,
    lastSuccessfulSyncTs: null,
    syncLog: [],
  };
}

/**
 * Retrieves store context from localStorage, or creates it if it doesn't exist.
 */
export function getInitialContext(): StoreContext {
  const persistedContext = maybeGetItem<PersistedStoreContext>(
    STORE_KEY,
    getFallbackPersistedContext(),
  );

  return {
    ...persistedContext,
    syncStatus: "INITIAL",
    authStatus: "INITIAL",
  };
}

/**
 * Retrieves store data from localStorage, taking into account legacy format,
 * or creates it if it doesn't exist.
 *
 * TODO: Decide whether this function can be removed once Ory is fully migrated.
 */
export function getInitialContextWithLegacyFallback(): StoreContext {
  const legacyKeys = {
    should_sync: "should_sync",
    last_sync_at: "last_sync_at",
    sync_log: "sync_log",
  };

  // First we check if legacy data exists
  const lastSuccessfulSyncTs = maybeGetItem<number | null>(legacyKeys.last_sync_at, null);
  const syncLog = maybeGetItem<LogItem[]>(legacyKeys.sync_log, []);

  // In this case we are dealing with legacy data
  if (lastSuccessfulSyncTs) {
    // Cleanup
    localStorage.removeItem(legacyKeys.should_sync);
    localStorage.removeItem(legacyKeys.last_sync_at);
    localStorage.removeItem(legacyKeys.sync_log);

    // Return store-compatible context.
    return {
      ...getFallbackPersistedContext(),
      // Kinda hate that we're creating a fake non-existent user here, but
      // I am not sure what alternative options we have at this point.
      currentUser: {
        email: "",
        id: "",
        isVerified: false,
      },
      authStatus: "INITIAL",
      syncStatus: "INITIAL",
      lastSuccessfulSyncTs,
      syncLog,
    };
  }

  // Otherwise we are dealing with a non-legacy data
  return getInitialContext();
}
