import { Image } from "@/ui/image";
import { useIntersectionObserver } from "@/ui/intersection-observer-list";
import { MoreMenu, MoreMenuItem, MoreMenuLinkItem, MoreMenuSeparator } from "@/ui/more-menu";
import { ScrollControls } from "@/ui/scroll-controls";
import { ReactNode } from "react";
import menuUrl from "../../images/menu.svg";
import * as css from "./style.css";
import { DialogButton } from "@/ui/dialog-button";
import { ShareBattleDialog } from "../share-battle-dialog";
import { BattleActions, BattleState } from "../battle-state/context";

export function SectionsContainer(props: {
  children: ReactNode[];
  state: BattleState;
  actions: BattleActions;
}) {
  const sections = props.children;

  const { containerRef, setElementRef, ...scrollControls } =
    useIntersectionObserver<HTMLDivElement>({
      key: sections.length.toString(),
    });

  return (
    <div className={css.container} ref={containerRef}>
      {sections.map((section, index) => {
        const id = index.toString();

        return (
          <div id={id} key={id} className={css.track} ref={setElementRef(id)}>
            {section}
          </div>
        );
      })}

      <div className={css.spacer}>
        <Image
          className={css.spacerIllustration}
          src="/images/sword.webp"
          width={140}
          height={420}
        />
      </div>

      <div className={css.controls}>
        <div className={css.scrollIndicators}>
          <ScrollControls {...scrollControls} sectionsIds={sections.map((_, i) => i.toString())} />
        </div>

        <div className={css.desktopControls}>
          <div className={css.divider} />

          <DialogButton
            className={css.shareButton}
            renderDialog={(dialogProps) => (
              <ShareBattleDialog battleState={props.state} {...dialogProps} />
            )}
          >
            Share Battle
          </DialogButton>

          <div className={css.divider} />

          <MoreMenu
            icon={<img src={menuUrl} alt="Menu" />}
            fallbackLabel="More menu"
            className={css.moreMenu}
            placement="top-end"
          >
            <MoreMenuItem onClick={props.actions.rerollAll}>Reroll all</MoreMenuItem>
            <MoreMenuSeparator />
            <MoreMenuLinkItem href="/settings">Settings</MoreMenuLinkItem>
          </MoreMenu>
        </div>
      </div>
    </div>
  );
}
