import { DialogProvider, useDialogContext, useStoreState } from "@ariakit/react";
import { ReactElement, ReactNode } from "react";

function DialogGuard(props: { children: ReactNode }) {
  const dialog = useDialogContext();
  const isMounted = useStoreState(dialog, (store) => store?.mounted);
  if (!isMounted) {
    return null;
  }
  return props.children;
}

export function DialogTrigger(props: { children: [dialog: ReactElement, trigger: ReactElement] }) {
  const [dialog, button] = props.children;
  return (
    <DialogProvider>
      <DialogGuard>{dialog}</DialogGuard>
      {button}
    </DialogProvider>
  );
}
