import { ExternalLink } from "../external-link";
import { interactiveText } from "../utils.css";
import * as css from "./style.css";

export function IndieTabletopClubSymbol() {
  return <img src="/logos/itc-symbol.svg" alt="" className={css.itcSymbol} />;
}

export function IndieTabletopClubFooter() {
  return (
    <div
      style={{
        textAlign: "center",
        paddingBlockStart: "2rem",
        borderBlockStart: "1px solid #ececec",
        marginBlockStart: "3rem",
      }}
    >
      <img src="/logos/itc-logo.svg" alt="" style={{ margin: "0 auto 1.125rem" }} />
      <p
        style={{
          margin: "0 auto",
          maxInlineSize: "25rem",
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
        }}
      >
        Indie Tabletop Club supports independent game creators with high-quality digital tools.{" "}
        <ExternalLink href="https://indietabletop.club" className={interactiveText}>
          Learn more
        </ExternalLink>
        .
      </p>
    </div>
  );
}
