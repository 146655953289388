import { ReactNode } from "react";
import * as css from "./style.css";

export function ListView(props: {
  items: ReactNode;
  stickyButton?: ReactNode;
  secondaryButton?: ReactNode;
}) {
  return (
    <div className={css.listView}>
      {props.items}

      {props.stickyButton && <div className={css.stickyButtonContainer}>{props.stickyButton}</div>}

      {props.secondaryButton}
    </div>
  );
}

export function ListDivider() {
  return <hr className={css.listDivider} />;
}
