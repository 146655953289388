import { config } from "@/config";
import { ArmyRecordData } from "@/schema/latest";
import { Failure, Success } from "@indietabletop/appkit/async-op";
import { IndieTabletopClient } from "@indietabletop/appkit/client";
import { FailurePayload } from "@indietabletop/appkit/types";
import { array, number, object, record, string } from "superstruct";
import { store } from "./store";

export function failureToString(failure: FailurePayload): string {
  switch (failure.type) {
    case "API_ERROR": {
      return `API responded with error status code: ${failure.code}`;
    }

    case "NETWORK_ERROR": {
      return `Could not reach the server due to a network error.`;
    }

    case "VALIDATION_ERROR": {
      return `Incoming data is using an outdated format. Try refreshing the app.`;
    }

    case "UNKNOWN_ERROR":
    default: {
      return `Unknown error.`;
    }
  }
}

class HobgoblinClient extends IndieTabletopClient {
  /**
   * Pulls changes data from the cloud.
   *
   * Setting the `sinceTs` param to null signals that this is the first pull on
   * this client and therefore deletes can be omitted.
   */
  async hobgoblinPullChanges(props: { sinceTs: number | null }) {
    const params = new URLSearchParams({
      sinceTs: `${props.sinceTs ?? 0}`,
      omitDeleted: `${!props.sinceTs}`,
    });
    return (await this.fetchWithAuth(`/hobgoblin/data?${params}`, array())) as
      | Success<ArmyRecordData[]>
      | Failure<FailurePayload>;
  }

  async hobgoblinPushAndPullChanges(props: {
    pushTs: number;
    pullSinceTs: number | null;
    armies: ArmyRecordData[];
  }) {
    if (props.armies.length === 0) {
      return new Success({ synced_count: 0, armies: [] });
    }

    return (await this.fetchWithAuth(
      `/hobgoblin/data`,
      object({ synced_count: number(), armies: array() }),
      {
        method: "PATCH",
        json: {
          armies: props.armies,
          syncedTs: props.pushTs,
          pullSinceTs: props.pullSinceTs ?? 0,

          // If pullSinceTs is null we are performing the first ever sync
          // on this client so it is safe to omit syncing deletes.
          pullOmitDeleted: !props.pullSinceTs,
        },
      },
    )) as Success<{ synced_count: number; armies: ArmyRecordData[] }> | Failure<FailurePayload>;
  }

  async postImageToStore(
    file: File,
    presigned: { fields: Record<string, string>; url: string; key: string },
  ): Promise<Success<string> | Failure<FailurePayload>> {
    const formData = new FormData();
    for (const [key, value] of Object.entries(presigned.fields)) {
      formData.append(key, value);
    }
    formData.append("file", file);

    try {
      const upload = await fetch(presigned.url, { method: "POST", body: formData });

      if (!upload.ok) {
        return new Failure({ type: "API_ERROR", code: upload.status });
      }

      return new Success(`/${presigned.key}`);
    } catch {
      return new Failure({ type: "NETWORK_ERROR" });
    }
  }

  async getPresignedUploadUrl(file: File) {
    return await this.fetchWithAuth(
      `/v1/images`,
      object({
        key: string(),
        fields: record(string(), string()),
        url: string(),
      }),
      {
        method: "POST",
        json: { size: file.size, type: file.type, name: file.name },
      },
    );
  }

  async uploadImage(file: File) {
    const presignedOp = await this.getPresignedUploadUrl(file);

    if (!presignedOp.isSuccess) {
      return presignedOp;
    }

    return await this.postImageToStore(file, presignedOp.value);
  }
}

export const client = new HobgoblinClient({
  apiOrigin: config.ITC_API_ORIGIN,
  onCurrentUser(currentUser) {
    store.send({ type: "SET_CURRENT_USER", currentUser });
  },
  onSessionInfo(sessionInfo) {
    store.send({ type: "PATCH_SESSION_INFO", sessionInfo });
  },
  onSessionExpired() {
    store.send({ type: "AUTHENTICATION_ERROR" });
  },
});
