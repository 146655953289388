import { Army } from "@/domain/army";
import { toLocaleString } from "@/lib/date-format";
import { CurrentUser, SessionInfo } from "@indietabletop/appkit/types";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "../link";
import { ViewContainer } from "../max-width-container";
import { MobileNavLayout } from "../mobile-nav-layout";
import { LogItem, store } from "../store";
import {
  useCurrentUser,
  useIsAuthError,
  useIsSyncing,
  useLastSuccessfulSyncTs,
  useLogOut,
  useSessionInfo,
  useSyncLog,
} from "../store/hooks";
import * as common from "../utils.css";
import * as css from "./style.css";

function LoggedOutSection() {
  return (
    <section className={common.section}>
      <h2 className={common.heading}>Account</h2>

      <p className={common.paragraph}>
        Join Indie Tabletop Club or log into your account to enable army backup and sync.{" "}
        <Link href="/help/backup-and-sync" className={common.interactiveText}>
          Learn more
        </Link>
        .
      </p>

      <div className={css.actions}>
        <Link className={css.actionButton} style={common.primaryTheme} href="/join">
          Join
        </Link>

        <Link className={css.actionButton} href="~/login">
          Log in
        </Link>
      </div>
    </section>
  );
}

function VerificationStatus(props: { currentUser: CurrentUser }) {
  const isAuthError = useIsAuthError();

  return (
    <>
      Verification status:{" "}
      {props.currentUser.isVerified ?
        "Verified"
      : <>
          {"Not verified. "}
          {isAuthError ?
            <>
              {"Please "}
              <Link href="~/login" className={common.interactiveText}>
                log in again
              </Link>
              {" and complete your verification."}
            </>
          : <>
              {"Please "}
              <Link className={common.interactiveText} href="~/verify">
                complete verification
              </Link>
              .
            </>
          }
        </>
      }
    </>
  );
}

function SessionDetails(props: { sessionInfo: SessionInfo }) {
  const { sessionInfo } = props;
  return (
    <>
      {"Session started: "}
      {toLocaleString(sessionInfo.createdTs, {
        dateStyle: "long",
        timeStyle: "medium",
      })}
      <br />
      {"Expires: "}
      {toLocaleString(sessionInfo.expiresTs, {
        dateStyle: "long",
        timeStyle: "medium",
      })}
    </>
  );
}

function AccountSection(props: { currentUser: CurrentUser; sessionInfo: SessionInfo | null }) {
  const { currentUser, sessionInfo } = props;
  const logOut = useLogOut();
  const isMigrated = currentUser.email === "";

  return (
    <section className={common.section}>
      <h2 className={common.heading}>Account</h2>
      {isMigrated ?
        <p className={common.paragraph}>Logged in as: N/A (session expired)</p>
      : <p className={common.paragraph}>
          Logged in as: {currentUser.email}
          <br />
          <VerificationStatus currentUser={currentUser} />
          <br />
          {sessionInfo ?
            <SessionDetails sessionInfo={sessionInfo} />
          : "Session: Expired"}
        </p>
      }

      <p className={common.paragraph}>
        <button className={common.interactiveText} onClick={logOut}>
          Log out
        </button>
      </p>
    </section>
  );
}

function typeToLabel(type: LogItem["type"]) {
  const record: Record<typeof type, string> = {
    PULL_SUCCESS: `✅ Pulled data from the cloud.`,
    PUSH_SUCCESS: `✅ Pushed data to the cloud.`,
    PUSH_NO_CHANGES: `🆗 Push not needed. Local data hasn't changed since last sync.`,
    PULL_NO_CHANGES: `🆗 Pull complete. Local data already up to date.`,
    SYNC_ERROR: `🛑 An error occurred during sync.`,
  };

  return record[type] ?? "🌀 Unrecognised log item type.";
}

function LogItemRow(props: LogItem) {
  const date = toLocaleString(props.ts, {
    dateStyle: "short",
    timeStyle: "medium",
  });

  return (
    <div className={css.logItem}>
      <div>
        <div>
          {typeToLabel(props.type)} <span className={css.timestamp}>{date}</span>
        </div>
      </div>

      {"records" in props && (
        <div className={css.logItemRecords}>
          {props.records.map((record) => {
            if (record.deleted) {
              return (
                <div key={record.id}>
                  Synced deletion of {record.name || Army.FALLBACK_NAME} (ID {record.id})
                </div>
              );
            }

            return (
              <div key={record.id}>
                Synced{" "}
                <Link href={`/army/a/${record.id}`} className={common.interactiveText}>
                  {record.name}
                </Link>{" "}
                (ID: {record.id})
              </div>
            );
          })}
        </div>
      )}

      {"error" in props && <div className={css.logItemRecords}>{props.error}</div>}
    </div>
  );
}

export function SyncLogError() {
  return (
    <section className={common.section}>
      <h2 className={common.heading}>Sync log failed to load due to error</h2>

      <button
        style={{ border: "1px solid red", padding: "0.5rem 1rem" }}
        onClick={() => {
          store.send({ type: "CLEAR_LOG" });
          location.reload();
        }}
      >
        Clear log and refresh
      </button>
    </section>
  );
}

function SyncLogSection() {
  const currentUser = useCurrentUser();
  const log = useSyncLog();
  const isSyncing = useIsSyncing();
  const lastSuccessfulSyncTs = useLastSuccessfulSyncTs();
  const isAuthError = useIsAuthError();

  if (!currentUser?.isVerified) {
    return (
      <section className={common.section}>
        <h2 className={common.heading}>Sync log</h2>
        <p className={common.paragraph}>
          Backup and sync disabled. Please verify your account to enable backup and sync.
        </p>
      </section>
    );
  }

  const lastSyncText =
    lastSuccessfulSyncTs ?
      `Last successful sync: ${toLocaleString(lastSuccessfulSyncTs, {
        dateStyle: "long",
        timeStyle: "medium",
      })}.`
    : "Not yet synced.";

  return (
    <section className={common.section}>
      <h2 className={common.heading}>Sync log</h2>

      {isAuthError && (
        <p className={common.paragraph}>
          Backup and sync disabled because your session has expired.
        </p>
      )}

      <p className={common.paragraph}>{lastSyncText}</p>

      <p className={common.paragraph}>
        <button
          className={common.interactiveText}
          onClick={() => {
            store.send({ type: "CLEAR_LOG" });
          }}
        >
          Clear log
        </button>
      </p>

      <div className={css.logItems}>
        {isSyncing && <div className={css.logItem}>Syncing...</div>}

        {log.length === 0 ?
          "No items"
        : log.map((item) => {
            const key = `${item.ts}${item.type}`;
            return <LogItemRow key={key} {...item} />;
          })
        }
      </div>
    </section>
  );
}

export function AccountPageMain() {
  const currentUser = useCurrentUser();
  const sessionInfo = useSessionInfo();
  const isAuthError = useIsAuthError();

  if (!currentUser) {
    return <LoggedOutSection />;
  }

  return (
    <>
      {isAuthError && (
        <section className={common.section}>
          <h2 className={common.heading}>Your session has expired</h2>
          <p className={common.paragraph}>
            {"This is most likely because you have not used the app in a while. "}
            <Link href="~/login" className={common.interactiveText}>
              Log in again
            </Link>
            {" to resume syncing."}
          </p>
        </section>
      )}

      <AccountSection currentUser={currentUser} sessionInfo={sessionInfo} />

      <ErrorBoundary fallback={<SyncLogError />}>
        <SyncLogSection />
      </ErrorBoundary>
    </>
  );
}

export function AccountPage() {
  return (
    <MobileNavLayout title="Account">
      <ViewContainer maxWidth={false} padding="small">
        <AccountPageMain />
      </ViewContainer>
    </MobileNavLayout>
  );
}
