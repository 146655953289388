import { currentUser, sessionInfo } from "@indietabletop/appkit/structs";
import { CurrentUser, SessionInfo } from "@indietabletop/appkit/types";
import { createStore } from "@xstate/store";
import {
  array,
  boolean,
  enums,
  Infer,
  literal,
  mask,
  nullable,
  number,
  object,
  optional,
  string,
  union,
} from "superstruct";
import { getInitialContextWithLegacyFallback } from "./getInitialContext";
import { withLocalStorage } from "./withLocalStorage";

export const STORE_VERSION = 1;

export const STORE_KEY = "store";

export type LogItemWithoutTimestamp = Omit<LogItem, "ts">;

export type LogItem = Infer<ReturnType<typeof logItem>>;

export type PersistedStoreContext = Infer<ReturnType<typeof persistedContext>>;

function logItem() {
  return union([
    object({
      type: enums(["PULL_SUCCESS", "PUSH_SUCCESS"]),
      records: array(object({ deleted: boolean(), id: string(), name: optional(string()) })),
      ts: number(),
    }),
    object({
      type: enums(["PUSH_NO_CHANGES", "PULL_NO_CHANGES"]),
      ts: number(),
    }),
    object({
      type: enums(["SYNC_ERROR"]),
      error: string(),
      ts: number(),
    }),
  ]);
}

function persistedContext() {
  return object({
    version: literal(STORE_VERSION),
    currentUser: nullable(currentUser()),
    sessionInfo: nullable(sessionInfo()),
    lastSuccessfulSyncTs: nullable(number()),
    syncLog: array(logItem()),
  });
}

export type StoreContext = PersistedStoreContext & {
  /**
   * Sync status represents the information about whether the app currently
   * syncing.
   *
   * This value is not persisted in local storage.
   */
  syncStatus: "INITIAL" | "SYNCING" | "AWAITING_CHANGES";

  /**
   * Auth status represents whether the latest API response returned an
   * authentication error.
   *
   * This value is not persisted in local storage.
   */
  authStatus: "INITIAL" | "AUTHENTICATED" | "AUTHENTICATION_ERROR";
};

export const store = createStore({
  context: getInitialContextWithLegacyFallback(),

  on: {
    SET_CURRENT_USER(_context, event: { currentUser: CurrentUser }): Partial<StoreContext> {
      return {
        currentUser: event.currentUser,
        syncStatus: "AWAITING_CHANGES",
        authStatus: "AUTHENTICATED",
      };
    },
    UNSET_CURRENT_USER() {
      return { currentUser: null };
    },

    AUTHENTICATION_ERROR(): Partial<StoreContext> {
      return { authStatus: "AUTHENTICATION_ERROR", sessionInfo: null };
    },

    PATCH_SESSION_INFO({ sessionInfo }, e: { sessionInfo: SessionInfo }) {
      return {
        sessionInfo: {
          // Do not update created timestamp when existing session info exists.
          // We want to keep that information around to know when the user
          // initially authenticated.
          createdTs: sessionInfo?.createdTs ?? e.sessionInfo.createdTs,
          expiresTs: e.sessionInfo.expiresTs,
        },
      };
    },

    ADD_LOG_ITEM(context, { item }: { item: LogItem }) {
      console[item.type === "SYNC_ERROR" ? "warn" : "info"](item);

      const items = context.syncLog.slice(0, 14);
      return { syncLog: [item, ...items] };
    },
    CLEAR_LOG() {
      return { syncLog: [] };
    },

    MARK_SUCCESSFUL_SYNC(_, event: { lastSuccessfulSyncTs: number }) {
      return { lastSuccessfulSyncTs: event.lastSuccessfulSyncTs };
    },

    SYNC_ONGOING(): Partial<StoreContext> {
      return { syncStatus: "SYNCING" };
    },
    SYNC_COMPLETE(): Partial<StoreContext> {
      return { syncStatus: "AWAITING_CHANGES" };
    },

    CLEAR_ALL(): StoreContext {
      return {
        currentUser: null,
        sessionInfo: null,
        lastSuccessfulSyncTs: null,
        syncLog: [],
        authStatus: "INITIAL",
        syncStatus: "INITIAL",
        version: STORE_VERSION,
      };
    },
    UPDATED_PERSISTED_CONTEXT(_, event: { persistedContext: PersistedStoreContext }) {
      return event.persistedContext;
    },
  },
});

withLocalStorage({
  store,
  key: STORE_KEY,
  getPersistedContext: (context): PersistedStoreContext => {
    return mask(context, persistedContext());
  },
  onStorage(value) {
    try {
      const context = mask(value, persistedContext());

      store.send({
        type: "UPDATED_PERSISTED_CONTEXT",
        persistedContext: context,
      });
    } catch (cause) {
      const error = new Error(`Could not update in-memory context based on 'storage' event.`, {
        cause,
      });

      console.error(error);
    }
  },
});
