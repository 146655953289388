declare global {
  interface ImportMetaEnv {
    VITE_ENABLE_SENTRY?: string;
    VITE_EXPOSE_ERRORS?: string;
    VITE_ITC_API_ORIGIN: string;
    VITE_BUCKET_NAME: string;
  }
}

export const config = {
  ENABLE_SENTRY: import.meta.env.VITE_ENABLE_SENTRY === "true",
  EXPOSE_ERRORS: import.meta.env.VITE_EXPOSE_ERRORS === "true",
  ITC_API_ORIGIN: import.meta.env.VITE_ITC_API_ORIGIN,
  BUCKET_NAME: import.meta.env.VITE_BUCKET_NAME,

  // Values inlined by Vite
  COMMIT_SHORTCODE: COMMIT_SHORTCODE,
  BRANCH: BRANCH,
};
