import { getIllustrationSrc, getImageUploadSrc } from "@/domain/s3-urls";
import { Unit } from "@/domain/unit";
import { ArmyImage } from "@/schema/latest";
import { useArmyOp } from "@/ui/army-provider/context";
import { Button } from "@/ui/button";
import { KnownErrorMessage } from "@/ui/db-error";
import { RulesetProvider } from "@/ui/game-content-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { Image } from "@/ui/image";
import { Link } from "@/ui/link";
import { ListView } from "@/ui/list-view";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { Paragraphs } from "@/ui/paragraphs";
import { ShareArmyDialog } from "@/ui/share-army-dialog";
import { AddUnitDialog } from "@/ui/unit-form-dialog";
import { button, interactiveText, primaryTheme } from "@/ui/utils.css";
import { DialogDisclosure } from "@ariakit/react";
import { useMediaQuery } from "@indietabletop/appkit/use-media-query";
import { ReactNode } from "react";
import { Route, Switch, useParams, useRoute } from "wouter";
import { ArmyActionsMenu } from "../army-actions-menu";
import { ArmyIssues } from "../army-issues";
import { DialogTrigger } from "../dialog-trigger";
import { KeywordsHandler } from "../keywords-handler";
import { LocalArmyProvider } from "../local-army-provider";
import { MinWidth } from "../media";
import { UnitMiniIcons } from "../mini-icon";
import { MobileNavLayout } from "../mobile-nav-layout";
import { color } from "../tokens";
import { UnitActionsMenu } from "../unit-actions-menu";
import { UnitCount } from "../unit-count";
import { UnitDetailView } from "../unit-detail-page";
import { UpgradeArmyRulesetDialog } from "../upgrade-army-ruleset-dialog";
import { BackButton, DesktopViewHeader } from "./desktop-view-header";
import { armyPhotoSize } from "./imageSizes";
import * as css from "./style.css";

export function ArmyDetailUnitCard(props: { unit: Unit; href: string }) {
  const [match] = useRoute(props.href);
  const { unit } = props;

  return (
    <Link href={props.href} className={css.unitLink} aria-current={match ? "true" : "false"}>
      <h2 className={css.unitHeading}>
        {unit.name}

        <UnitCount count={unit.count} />

        <UnitMiniIcons keywords={unit.keywords} />
      </h2>

      <div className={css.unitSummary}>
        {!unit.isValid() && (
          <>
            <span style={{ color: color.PURPLE }}>Invalid</span>
            {" · "}
          </>
        )}
        <span>{unit.summary}</span>

        {unit.keywords.length > 0 && (
          <span className={css.unitKeywords} style={{ marginTop: "0.25rem" }}>
            {" · "}
            {unit.keywordsSummary}
          </span>
        )}
      </div>
    </Link>
  );
}

function AddUnitButton() {
  const armyOp = useArmyOp();

  if (!armyOp.isSuccess) {
    return null;
  }

  return (
    <DialogTrigger>
      <AddUnitDialog army={armyOp.value} />

      <DialogDisclosure className={button} style={primaryTheme}>
        Add Unit
      </DialogDisclosure>
    </DialogTrigger>
  );
}

function ShareArmyButton() {
  const armyOp = useArmyOp();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && armyOp.isSuccess && (
        <ShareArmyDialog
          store={store}
          armyData={armyOp.value.data}
          armyShareTitle={`${armyOp.value.name} (${armyOp.value.summary})`}
        />
      )}

      <button className={button} type="button" onClick={setOpen}>
        Share army
      </button>
    </>
  );
}

function CoverImage(props: { image: ArmyImage }) {
  if (props.image.type === "ILLUSTRATION") {
    return (
      <Image
        src={getIllustrationSrc(props.image.id)}
        alt=""
        width={600}
        height={600}
        className={css.armyIllustration}
        optimize
      />
    );
  }

  return (
    <div className={css.armyPhotoContainer}>
      <Image
        {...armyPhotoSize}
        className={css.armyPhoto}
        src={getImageUploadSrc(props.image.src)}
        alt=""
        optimize
      />
    </div>
  );
}

function UpdateArmyRulesetButton() {
  const armyOp = useArmyOp();
  const { store, isOpen, setOpen } = useDialog();

  return (
    <>
      {isOpen && armyOp.isSuccess && <UpgradeArmyRulesetDialog store={store} army={armyOp.value} />}

      <Button className={interactiveText} onClick={setOpen}>
        Upgrade ruleset?
      </Button>
    </>
  );
}

export function ArmyDetailView(props: {
  stickyButton?: ReactNode;
  secondaryButton?: ReactNode;
  actionsMenu?: ReactNode;
  updateArmyButton?: ReactNode;
  headerLabel: string;
}) {
  const armyOp = useArmyOp();

  if (armyOp.isPending) {
    return <LoadingIndicator />;
  }

  if (armyOp.isFailure) {
    return <KnownErrorMessage error={armyOp.failure} />;
  }

  const army = armyOp.value;

  const { mainImage } = army;

  return (
    <RulesetProvider ruleset={army.ruleset}>
      <KeywordsHandler>
        <div className={css.armyView}>
          <div className={css.view}>
            <div className={css.cover}>
              <DesktopViewHeader
                label={props.headerLabel}
                menu={props.actionsMenu}
                back={<BackButton href="~/army" />}
              />

              <header
                className={css.armyHeader({
                  centerText: mainImage?.type === "ILLUSTRATION",
                })}
              >
                {mainImage && <CoverImage image={mainImage} />}

                <h1 className={css.armyName}>{army.name}</h1>
                <div className={css.armySummary}>{army.summary}</div>
              </header>

              <ArmyIssues army={army} updateArmyButton={props.updateArmyButton} />

              {army.description && (
                <div className={css.description}>
                  <Paragraphs text={army.description} />
                </div>
              )}
            </div>

            <ListView
              items={army.units.map((unit) => (
                <ArmyDetailUnitCard href={`/u/${unit.id}`} key={unit.id} unit={unit} />
              ))}
              stickyButton={props.stickyButton}
              secondaryButton={props.secondaryButton}
            />
          </div>
        </div>
      </KeywordsHandler>
    </RulesetProvider>
  );
}

function UnitEmptyState() {
  return (
    <div className={css.unitEmptyState}>
      <Image
        src="/images/magic_banner.webp"
        alt=""
        width={600}
        height={400}
        className={css.unitEmptyStateImage}
      />
      <div className={css.unitEmptyStateHint}>Select a unit</div>
    </div>
  );
}

export function ArmyPageLayout(props: {
  armyTitle: string;
  armyDetail: ReactNode;
  armyNavAction?: ReactNode;
  unitTitle: string;
  unitDetail: ReactNode;
  unitNavAction?: ReactNode;
}) {
  const isWideViewport = useMediaQuery(MinWidth.WIDE);

  if (!isWideViewport) {
    return (
      <Switch>
        <Route path="/">
          <MobileNavLayout title={props.armyTitle} action={props.armyNavAction} backHref="~/army">
            {props.armyDetail}
          </MobileNavLayout>
        </Route>

        <Route path="/u/:unitId">
          <MobileNavLayout title={props.unitTitle} action={props.unitNavAction} backHref="/">
            {props.unitDetail}
          </MobileNavLayout>
        </Route>
      </Switch>
    );
  }

  return (
    <div className={css.viewsContainer}>
      <div className={css.viewTrack}>{props.armyDetail}</div>

      <div className={css.viewTrack}>
        <div className={css.unitView}>
          <Switch>
            <Route path="/u/:unitId">{props.unitDetail}</Route>

            <Route>
              <UnitEmptyState />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export function ArmyPages() {
  const params = useParams<{ armyId: string }>();

  return (
    <LocalArmyProvider armyId={params.armyId}>
      <ArmyPageLayout
        armyTitle="Army"
        armyNavAction={<ArmyActionsMenu iconColor="white" />}
        armyDetail={
          <ArmyDetailView
            headerLabel="Army"
            stickyButton={<AddUnitButton />}
            updateArmyButton={<UpdateArmyRulesetButton />}
            actionsMenu={<ArmyActionsMenu />}
            secondaryButton={<ShareArmyButton />}
          />
        }
        unitTitle="Unit"
        unitNavAction={<UnitActionsMenu iconColor="white" />}
        unitDetail={<UnitDetailView headerLabel="Unit" />}
      />
    </LocalArmyProvider>
  );
}
