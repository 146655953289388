import cross from "@/images/cross.svg";
import { Image } from "@/ui/image";
import { KeywordsHandler } from "@/ui/keywords-handler";
import { DialogDismiss, DialogStore, Form, FormStore } from "@ariakit/react";
import { ReactNode } from "react";
import { FormSubmitButton } from "../form-submit-button";
import { ModalDialog } from "../modal-dialog";
import { primaryTheme } from "../utils.css";
import * as css from "./style.css";

type FormDialogHeaderProps = {
  heading: string;
  subheading?: string;
};

function FormDialogHeader(props: FormDialogHeaderProps) {
  return (
    <div className={css.header}>
      <div />
      <div>
        <h2 className={css.heading}>{props.heading}</h2>

        {props.subheading && <div className={css.subheading}>{props.subheading}</div>}
      </div>

      <DialogDismiss>
        <Image src={cross} alt="Dismiss" width={40} height={40} />
      </DialogDismiss>
    </div>
  );
}

type FormDialogProps = {
  store: DialogStore;
  children: ReactNode;
  onSubmit?: () => Promise<void>;
  submit: string;
  disabled?: boolean;
};

/**
 * @deprecated Use {@link FormDialogV2} where possible.
 */
export function FormDialog(props: FormDialogProps & FormDialogHeaderProps) {
  return (
    <ModalDialog store={props.store} className={css.dialog}>
      <KeywordsHandler>
        <form
          className={css.content}
          onSubmit={async (event) => {
            event.preventDefault();

            if (!props.disabled) {
              try {
                await props.onSubmit?.();
                props.store.hide();
              } catch (error) {
                console.error(error);
              }
            }
          }}
        >
          <FormDialogHeader {...props} />

          {props.children}

          <div className={css.submitButtonContainer}>
            <button
              type="submit"
              className={css.submitButton}
              style={primaryTheme}
              disabled={props.disabled}
            >
              {props.submit}
            </button>
          </div>
        </form>
      </KeywordsHandler>
    </ModalDialog>
  );
}

type FormDialogV2Props = {
  form: FormStore;
  children: ReactNode;
  submitDisabled?: boolean;
  submitLabel: string;
};

/**
 * Renders a modal dialog with nested form.
 *
 * Note that compared to {@link FormDialog} (v1), this version relies on AriaKit forms.
 */
export function FormDialogV2(props: FormDialogV2Props & FormDialogHeaderProps) {
  return (
    <ModalDialog className={css.dialog}>
      <KeywordsHandler>
        <Form store={props.form} autoComplete="off" className={css.content} resetOnSubmit={false}>
          <FormDialogHeader {...props} />

          {props.children}

          <div className={css.submitButtonContainer}>
            <FormSubmitButton
              label={props.submitLabel}
              className={css.submitButton}
              disabled={props.submitDisabled}
            />
          </div>
        </Form>
      </KeywordsHandler>
    </ModalDialog>
  );
}
