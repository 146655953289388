import { Unit } from "@/domain/unit";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDatabase } from "@/ui/database-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useNavigate } from "@/ui/hooks/use-navigate";
import { primaryTheme } from "@/ui/utils.css";
import { useParams } from "wouter";
import { useArmyOp } from "../army-provider/context";
import { DialogTrigger } from "../dialog-trigger";
import {
  MoreMenu,
  MoreMenuDialogDisclosureItem,
  MoreMenuItem,
  MoreMenuSeparator,
} from "../more-menu";
import { EditUnitDialog } from "../unit-form-dialog";

function DuplicateUnitMenuItem(props: { unit: Unit }) {
  const database = useDatabase();
  const navigate = useNavigate();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          heading={`Duplicate unit?`}
          message={`Reserved keywords and cursed artefacts will not be copied over.`}
          store={store}
          onSubmit={async () => {
            const unitId = await database.duplicateUnit(props.unit.army.id, props.unit.data);
            navigate(`/u/${unitId}`);
          }}
          submit="Duplicate"
          submitTheme={primaryTheme}
        />
      )}

      <MoreMenuItem onClick={setOpen}>Duplicate</MoreMenuItem>
    </>
  );
}

function DeleteUnitMenuItem(props: { unit: Unit }) {
  const database = useDatabase();
  const navigate = useNavigate();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          heading={`Delete unit?`}
          message={`Are you sure you want to delete "${props.unit.name}"?`}
          store={store}
          onSubmit={async () => {
            await database.removeUnit(props.unit.army.id, props.unit.id);
            navigate(`/`);
          }}
          submit="Delete"
        />
      )}

      <MoreMenuItem onClick={setOpen}>Delete</MoreMenuItem>
    </>
  );
}

export function UnitActionsMenu(props: { iconColor?: string }) {
  const params = useParams<{ unitId: string }>();
  const armyOp = useArmyOp();
  const unit = armyOp.valueOrNull()?.getUnit(params.unitId);

  if (!unit) {
    return null;
  }

  return (
    <MoreMenu fallbackLabel="Unit actions" iconColor={props.iconColor}>
      <DialogTrigger>
        <EditUnitDialog unit={unit} />
        <MoreMenuDialogDisclosureItem>Edit</MoreMenuDialogDisclosureItem>
      </DialogTrigger>

      <DuplicateUnitMenuItem unit={unit} />

      <MoreMenuSeparator />

      <DeleteUnitMenuItem unit={unit} />
    </MoreMenu>
  );
}
