import beastUrl from "./images/beast.webp";
import coverUrl from "./images/cover.webp";
import coverDetailUrl from "./images/cover_detail.webp";
import elfArcherUrl from "./images/elf-archer.webp";
import elfWarriorUrl from "./images/elf-warrior.webp";
import iceZombiesUrl from "./images/ice-zombies.webp";

export type TextNode = {
  type: string;
  text: string;
  marks?: { type: string; attrs?: Record<string, string> }[];
};

function text(text: string): TextNode {
  return { type: "text", text };
}

function bold(text: string): TextNode {
  return { type: "text", text, marks: [{ type: "bold" }] };
}

function link(props: { href: string }, text: string): TextNode {
  return {
    type: "text",
    text,
    marks: [{ type: "link", attrs: { href: props.href } }],
  };
}

function heading(attrs: { level: 1 | 2 | 3 | 4 | 5 | 6 }, text: string) {
  return {
    type: "heading" as const,
    attrs,
    content: [{ type: "text", text }],
  };
}

function paragraph(text: string | TextNode[]) {
  return {
    type: "paragraph" as const,
    content: typeof text === "string" ? [{ type: "text", text }] : text,
  };
}

function image(attrs: { src: string }) {
  return {
    type: "image" as const,
    attrs,
  };
}

function button(text: string, path: string) {
  return {
    type: "button" as const,
    text,
    attrs: { path },
  };
}

function unorderedList(content: Array<ReturnType<typeof listItem>>) {
  return {
    type: "unorderedList" as const,
    content,
  };
}

function orderedList(content: Array<ReturnType<typeof listItem>>) {
  return {
    type: "orderedList" as const,
    content,
  };
}

function listItem(text: string | TextNode[]) {
  return {
    type: "listItem" as const,
    content: [paragraph(text)],
  };
}

export const page = {
  title: `Quick Start Rules`,
  subtitle: `Hobgoblin`,
  mainImageUrl: coverUrl,
  contentCoverImageUrl: coverDetailUrl,
  ctaImageUrl: beastUrl,
  ctaKicker: "Ready for more?",
  ctaHeading: "Get the rules",
  ctaDescription: "Get the full rules from Electi Studio website.",
  ctaButtonDigitalLabel: "Buy rulebook",
  ctaButtonPhysicalLabel: "Pre-order hardcover",
  content: [
    heading({ level: 2 }, "The Rule of Carnage"),
    paragraph(
      `In Hobgoblin, if the rules are ever unclear, pick whichever option results in the most carnage for all concerned.`,
    ),

    heading({ level: 2 }, "Build Your Army"),
    paragraph(
      `For your first game, build a small army of 3000 points. Standard games are played at 4000–5000 points. Select units from the unit profiles chart below and add the cost of any extra keywords you want to add to them (doubled for units with Keyword Cost Multiplier of 2). These Quick Start Rules include a sample of keywords you can buy for your units at the stated cost. See the Hobgoblin rulebook for a full list of keywords to customise your units, as well as rules for spells, magic items and battle standards.`,
    ),
    paragraph([
      text(`Alternatively, you can use the `),
      link({ href: "~/army" }, `army builder`),
      text(` available within this app, with two sample armies to get you started.`),
    ]),
    button(`View sample keywords`, "/keywords"),
    image({ src: elfArcherUrl }),

    heading({ level: 2 }, `Units`),
    paragraph(
      `All units are rectangles with an obvious front edge. Give each of your units a Unit Type, which determines their stats, target numbers and the keywords it gets for free. Units with Wide footprint must have a front edge that is 100-200mm wide, those with a Narrow footprint 40-100mm wide. A unit’s side edges must be 40-150mm long.`,
    ),
    button(`View unit profiles`, "/profiles"),

    heading({ level: 2 }, `Keywords`),
    paragraph(
      `Keywords grant special rules to units. A unit cannot have multiple copies of the same keyword, unless it contains a number (e.g. Swift [2]) in which case it stacks.`,
    ),

    heading({ level: 2 }, `Doom`),
    paragraph(
      `A unit which has 10+ Doom (i.e. equal to or greater than its Courage) is Broken. A unit that is Broken causes panic and is removed from play during the Doom Phase; either fled or slaughtered. Use tokens or dice to track Doom.`,
    ),

    heading({ level: 2 }, `Setting Up a Battle`),
    paragraph(
      `Set up some fantasy terrain on a table at least 4’x3’. Roll-off: the winner gets the First Player token. Divide the table in half, and set up your armies on either side, at least 16” apart, alternating the placement of units. See the Hobgoblin rulebook for more ways to set up your battle and full terrain rules.`,
    ),
    image({ src: elfWarriorUrl }),

    heading({ level: 2 }, `Battle Rounds`),
    paragraph(
      `A game of Hobgoblin is divided into five Battle Rounds. Each battle round is split into six Phases:`,
    ),

    heading({ level: 3 }, `1. Initiative Phase`),
    paragraph([
      bold(`Press the Initiative: `),
      text(
        `if First Player rolls higher than the current round number they may choose to keep the First Player token or pass it, otherwise they must pass it.`,
      ),
    ]),

    heading({ level: 3 }, `2. Magic Phase`),
    paragraph(`See the Hobgoblin rulebook for the magic rules.`),

    heading({ level: 3 }, `3. Shooting Phase`),
    paragraph(
      `The first player shoots with any of their unengaged ranged units. When a unit shoots it gains Distracted. The Second Player then shoots with their unengaged ranged units.`,
    ),
    paragraph(
      `Units roll 5 attack dice (D6) when shooting. Check the target number chart: rolls that equal or beat that target number are hits. Place 1 Doom on the target for each hit, stopping if the target has 5 Doom (the rest of the hits are ignored).`,
    ),

    heading({ level: 3 }, `4. Movement Phase`),
    paragraph(
      `In the Movement Phase, the First Player moves each of their units. The Second Player then does the same. When an army moves, each unit gains movement points equal to its Speed. Movement points can be spent to perform any of the manoeuvres listed below, any number of times, in any order they like:`,
    ),
    unorderedList([
      listItem([bold(`Advance (1+): `), text(`move directly forward 1”.`)]),
      listItem([bold(`Shuffle (1+): `), text(`move directly left or right ½”.`)]),
      listItem([bold(`Retreat (1+): `), text(`move backwards ½” and gain Distracted.`)]),
      listItem([
        bold(`Wheel (1+) `),
        text(
          `one front corner forward or backwards 1”, hinging around the other front corner. Wheeling backwards gains you Distracted.`,
        ),
      ]),
      listItem([
        bold(`Reform (5): `),
        text(
          `spend 5 movement points to spin the unit about its centrepoint to face any direction, gaining Distracted.`,
        ),
      ]),
    ]),
    paragraph(
      `A unit that is Distracted must not end a manoeuvre engaged with an enemy unit it didn’t start that manoeuvre engaged with.`,
    ),

    heading({ level: 3 }, `5. Combat Phase`),
    paragraph(
      `The First Player must resolve combat attacks with each of their engaged units, in an order of their choosing. The Second Player then does the same.`,
    ),
    paragraph(
      `Units roll 10 attack dice (D6) in combat. Check the target number chart: rolls that equal or beat that target number are hits. Place 1 Doom on the target for each hit.`,
    ),
    paragraph(
      `If a unit is touching multiple enemy units, they assign their 10 attack dice among those enemies, assigning at least 3 attack dice to each. See the Hobgoblin rulebook for rules for outflanking enemy units.`,
    ),
    button(`View target numbers chart`, "/target-numbers"),
    image({ src: iceZombiesUrl }),

    heading({ level: 3 }, `6. Doom Phase`),
    paragraph(`In the Doom Phase, carry out the following steps:`),
    orderedList([
      listItem([
        bold(`Panic: `),
        text(
          `All units gain 1 Doom for each friendly Broken unit within 8”. If this causes the unit to become Broken, that unit causes panic too.`,
        ),
      ]),
      listItem([
        bold(`Remove Broken Units: `),
        text(`Remove all Broken units from play simultaneously.`),
      ]),
      listItem([
        bold(`Death’s Harvest: `),
        text(
          `Gain a fortune card or two Mercury Token for each of your Broken units that was removed from play in the last step. See the Hobgoblin rulebook for how Fortune Cards and Magic work.`,
        ),
      ]),
      listItem([
        bold(`Who’s Next?: `),
        text(
          `Any unit that was engaged at the start of this Doom Phase and is now unengaged may make a free reform manoeuvre.`,
        ),
      ]),
      listItem([bold(`Ready Units: `), text(`Remove the Distracted keyword from all units.`)]),
    ]),

    heading({ level: 2 }, `Game End & Victory`),
    paragraph(
      `The game ends after five battle rounds and the player with the most points still on the table wins. See the Hobgoblin rulebook for more scenarios and victory conditions.`,
    ),
  ],
};
