import { KeywordDefinition } from "@/domain/keyword-definition";
import { KeywordPurchaseOption, KeywordRef, replaceKeywordValue } from "@/domain/keyword-types";
import { NumericalKeywordType } from "@/domain/numerical-keyword-type";
import { Ruleset } from "@/domain/ruleset";
import { Trait } from "@/domain/traits";
import { Unit } from "@/domain/unit";
import { UnitType } from "@/domain/unit-type";
import { sortByImportance } from "@/lib/sort";
import { KeywordRefData } from "@/schema/latest";
import { Body } from "@/ui/body";
import { FormDialog } from "@/ui/form-dialog";
import { useRuleset } from "@/ui/game-content-provider";
import { Checkbox } from "@/ui/inputs/checkbox";
import { DialogStore } from "@ariakit/react";
import { useState } from "react";
import { KeywordMiniIcon } from "../mini-icon";
import * as css from "./style.css";

function Keyword(props: {
  name: string;
  cost: number;
  keywordId: string;
  description: string;
  disabled: boolean;
  checked: boolean;
  onChecked: (name: string) => void;
  onUnchecked: (name: string) => void;
}) {
  return (
    <div className={css.keyword}>
      <div className={css.keywordHeader}>
        <h2 className={css.keywordHeading}>
          <Checkbox
            label={
              <>
                {props.name} <KeywordMiniIcon keywordId={props.keywordId} />
              </>
            }
            name={`${props.name}${props.cost}`}
            checked={props.checked}
            disabled={props.disabled}
            onChecked={props.onChecked}
            onUnchecked={props.onUnchecked}
          />
        </h2>
        <div>{props.cost === 0 ? "Free" : `${props.cost}pts`}</div>
      </div>

      <Body html={props.description} fontSize="0.875rem" omitReferenceFor={props.name} />
    </div>
  );
}

function getMapKey(id: string, value?: number) {
  if (value) {
    return `${id}/${value}`;
  }

  return id;
}

function toMap(keywords: KeywordRefData[]) {
  const map = new Map<string, (typeof keywords)[number]>();

  for (const keywordRef of keywords) {
    const key = getMapKey(keywordRef.id, keywordRef.numerical?.value);
    map.set(key, keywordRef);
  }

  return map;
}

function toRef(keywordId: string, purchaseOption: KeywordPurchaseOption) {
  const keywordRef: KeywordRef = {
    id: keywordId,
  };

  if (purchaseOption.value) {
    keywordRef.numerical = {
      value: purchaseOption.value,
      type: NumericalKeywordType.INCREMENT,
    };
  }

  return keywordRef;
}

function createKeywordMeta(rules: Ruleset) {
  type KeywordMeta = {
    purchasable: {
      [Trait.STRENGTH]: KeywordDefinition[];
      [Trait.WEAKNESS]: KeywordDefinition[];
    };
  };

  const meta: KeywordMeta = {
    purchasable: {
      [Trait.STRENGTH]: [],
      [Trait.WEAKNESS]: [],
    },
  };

  for (const keyword of rules.keywordsDefs) {
    if (keyword.traits.includes(Trait.STRENGTH) && keyword.purchaseOptions.length > 0) {
      meta.purchasable[Trait.STRENGTH].push(keyword);
    }

    if (keyword.traits.includes(Trait.WEAKNESS) && keyword.purchaseOptions.length > 0) {
      meta.purchasable[Trait.WEAKNESS].push(keyword);
    }
  }

  // Sort General and Battle Standard to top
  meta.purchasable[Trait.STRENGTH].sort(sortByImportance);

  return meta;
}

export function KeywordsDialog(props: {
  unit: Unit;
  onSubmit: (keywordRefs: KeywordRefData[]) => Promise<void>;
  store: DialogStore;
}) {
  const ruleset = useRuleset();

  const [selectedTrait, setSelectedTrait] = useState<Trait.STRENGTH | Trait.WEAKNESS>(
    Trait.STRENGTH,
  );

  const [selectedKeywords, setSK] = useState<Map<string, KeywordRefData>>(
    toMap(props.unit.data.keywords),
  );

  function getKeywordsHint() {
    switch (selectedTrait) {
      case Trait.STRENGTH: {
        return `You may select any number of strengths. General and Battle Standard may be added once per army.`;
      }

      case Trait.WEAKNESS: {
        const artilleryHint =
          props.unit.type === UnitType.ARTILLERY ?
            ` Artillery may only purchase the Self-Destructive and Short-Ranged weaknesses.`
          : ``;

        return `You may select up to two weaknesses.${artilleryHint}`;
      }
    }
  }

  const keywordsMeta = createKeywordMeta(ruleset);

  return (
    <FormDialog
      store={props.store}
      heading="Keywords"
      subheading={`${props.unit.keywordCostMultiplier}x keword cost multiplier`}
      onSubmit={async () => {
        const selectedRefs = Array.from(selectedKeywords.values());

        // Re-map back from the default order of keywords.
        const kws = [
          ...keywordsMeta.purchasable[Trait.STRENGTH],
          ...keywordsMeta.purchasable[Trait.WEAKNESS],
        ].flatMap((kw) => {
          return selectedRefs.filter((ref) => ref.id === kw.id);
        });

        return props.onSubmit(kws);
      }}
      submit="Done"
    >
      <div className={css.toggles}>
        <button
          className={css.toggle[selectedTrait === Trait.STRENGTH ? "active" : "inactive"]}
          type="button"
          onClick={() => {
            setSelectedTrait(Trait.STRENGTH);
          }}
        >
          Strengths
        </button>
        <button
          className={css.toggle[selectedTrait === Trait.WEAKNESS ? "active" : "inactive"]}
          type="button"
          onClick={() => {
            setSelectedTrait(Trait.WEAKNESS);
          }}
        >
          Weaknesses
        </button>
      </div>

      <div className={css.keywords}>
        <p className={css.keywordsHint}>{getKeywordsHint()}</p>

        {keywordsMeta.purchasable[selectedTrait].flatMap((keywordDef) => {
          return keywordDef.purchaseOptions.map((purchaseOption) => {
            const optionId = getMapKey(keywordDef.id, purchaseOption.value);
            const keywordRef = toRef(keywordDef.id, purchaseOption);

            const value = `+${purchaseOption.value}`;
            const name =
              purchaseOption.value ? replaceKeywordValue(keywordDef.name, value) : keywordDef.name;

            // We're intentionally omitting the operator here from the keyword
            // description. It reads better that way.
            const description =
              purchaseOption.value ?
                replaceKeywordValue(keywordDef.descriptionWithTraits, purchaseOption.value)
              : keywordDef.descriptionWithTraits;

            return (
              <Keyword
                key={optionId}
                name={name}
                keywordId={keywordDef.id}
                description={description}
                cost={purchaseOption.cost * props.unit.keywordCostMultiplier}
                checked={selectedKeywords.has(optionId)}
                disabled={false}
                onChecked={() => {
                  setSK((kws) => {
                    const map = new Map(kws);
                    map.set(optionId, keywordRef);
                    return map;
                  });
                }}
                onUnchecked={() => {
                  setSK((kws) => {
                    const map = new Map(kws);
                    map.delete(optionId);
                    return map;
                  });
                }}
              />
            );
          });
        })}
      </div>
    </FormDialog>
  );
}
