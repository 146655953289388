export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(isString);
}

export function isStringOrStringArray(value: unknown): value is string | string[] {
  return isString(value) || isStringArray(value);
}

export function isOneOf<T extends string>(value: string, enums: T[]): value is T {
  for (const e of enums) {
    if (e === value) {
      return true;
    }
  }

  return false;
}

export function isNonEmpty(item: unknown[]) {
  return item.length > 0;
}

export function isEmpty(item: unknown[]) {
  return item.length === 0;
}

export function isNullish(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}
