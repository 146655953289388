import { config } from "@/config";
import { caughtValueToString } from "@indietabletop/appkit/caught-value";
import * as css from "./styles.css";

export function ErrorMessage(props: { message: string; details?: unknown; code?: string }) {
  return (
    <div className={css.container}>
      <div className={css.message}>{props.message}</div>

      {props.code && <div className={css.code}>Error: {props.code}</div>}

      {config.EXPOSE_ERRORS && !!props.details && (
        <div
          style={{
            marginTop: "1rem",
            fontFamily: "monospace",
            padding: "1rem",
            border: "1px solid black",
            textAlign: "left",
          }}
        >
          <strong>Error:</strong> {caughtValueToString(props.details)}
        </div>
      )}
    </div>
  );
}
