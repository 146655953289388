import { HobgoblinDatabase } from "@/lib/db";
import { useAsyncOp } from "@indietabletop/appkit/use-async-op";
import { ReactNode, useEffect } from "react";
import { DatabaseOpenRequest, database } from "./context";
import { InaccessibleDatabaseError } from "./types";
import { toKnownError } from "./utils";

export function DatabaseProvider(props: { children: ReactNode }) {
  const { op, setSuccess, setFailure } = useAsyncOp<HobgoblinDatabase, InaccessibleDatabaseError>();

  useEffect(() => {
    database
      .open({
        onBlocking() {
          setFailure({ type: "CLOSED_FOR_UPGRADE" });
          database.close();
          console.info("Database closed due to version upgrade.");
        },
      })
      .then(
        () => {
          setSuccess(database);
          console.info("Database open.");
        },
        (error: unknown) => {
          setFailure(toKnownError(error));
          console.warn(`Request to open database failed.`);
        },
      );

    return () => {
      database.close();
      console.info("Database closed.");
    };
  }, [setFailure, setSuccess]);

  return <DatabaseOpenRequest.Provider value={op}>{props.children}</DatabaseOpenRequest.Provider>;
}
