import { isOneOf } from "../lib/typeguards.js";

export enum NumericalKeywordType {
  BASE = "BASE",
  INCREMENT = "INCREMENT",
}

export function isNumericalKeywordType(type: string): type is NumericalKeywordType {
  return isOneOf(type, Object.values(NumericalKeywordType));
}

export function assertNumericalKeywordType(type: string): asserts type is NumericalKeywordType {
  if (!isNumericalKeywordType(type)) {
    throw new Error(`${type} is not a valid NumericalKeywordType.`);
  }
}
