import { useDialogStore, useStoreState } from "@ariakit/react";
import { useEffect } from "react";
import { usePathname } from "./use-pathname";

export function useDialog(props?: { initialIsOpen?: boolean }) {
  const store = useDialogStore({
    animated: true,
    defaultOpen: props?.initialIsOpen,
  });

  // Close dialog whenever pathname changes
  const pathname = usePathname();
  useEffect(() => {
    store.hide();
  }, [store, pathname]);

  const isAnimating = useStoreState(store, "animating");
  const isOpen = useStoreState(store, "open");
  const isOpenOrOpening = isAnimating || isOpen;

  return {
    isOpen: isOpenOrOpening,
    setOpen: store.show,
    setClosed: store.hide,
    toggleOpen: isOpenOrOpening ? store.hide : store.show,
    store,
  };
}
