import { config } from "@/config";
import { ArmyData } from "@/schema/latest";
import { caughtValueToString } from "@indietabletop/appkit/caught-value";
import { useAsyncOp } from "@indietabletop/appkit/use-async-op";
import { useEffect } from "react";
import { useGameContentLookup } from "../game-content-provider";

type TransportError = { type: "FETCH_ERROR"; error: string };

declare global {
  interface Window {
    /**
     * This value may be supplied by the server.
     *
     * See `netlify/functions/share.ts` for more detail.
     */
    PREFETCHED?: { sharedArmy?: ArmyData };
  }
}

export function useFetchSharedArmy(shareId: string) {
  const game = useGameContentLookup();
  const { op, setSuccess, setFailure } = useAsyncOp<
    ArmyData,
    TransportError | { type: "ARMY_NOT_FOUND"; message?: string }
  >();

  useEffect(() => {
    async function getSharedArmy() {
      const prefetchedSharedArmy = window.PREFETCHED?.sharedArmy;

      if (prefetchedSharedArmy && prefetchedSharedArmy.share_id === shareId) {
        setSuccess(prefetchedSharedArmy);
        return;
      }

      try {
        const response = await fetch(`${config.ITC_API_ORIGIN}/hobgoblin/snapshots/${shareId}`);

        switch (response.status) {
          case 200: {
            const data = (await response.json()) as ArmyData;
            setSuccess(data);
            return;
          }

          case 404: {
            setFailure({
              type: "ARMY_NOT_FOUND",
              message: "Shared army with the specified ID was not found.",
            });
            return;
          }

          default: {
            setFailure({
              type: "FETCH_ERROR",
              error: "Could not fetch Shared Army.",
            });
            return;
          }
        }
      } catch (error) {
        console.error(error);

        setFailure({
          type: "FETCH_ERROR",
          error: caughtValueToString(error),
        });
      }
    }

    void getSharedArmy();
  }, [shareId, setSuccess, setFailure, game]);

  return op;
}
