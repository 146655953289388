import { migrateArmy } from "@/domain/migrations";
import { Ruleset } from "@/domain/ruleset";
import { SettingsData } from "@/schema/latest";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDatabase } from "@/ui/database-provider";
import { useGameContentLookup } from "@/ui/game-content-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { Link } from "@/ui/link";
import { useUserSettingsOp } from "@/ui/user-settings/context";
import { interactiveText, primaryTheme } from "@/ui/utils.css";
import { DialogStore } from "@ariakit/react";
import { useArmiesRulesets } from "../armies-rulesets/context";
import * as css from "./style.css";

function SwitchRulesetDialog(props: {
  store: DialogStore;
  latestRuleset: Ruleset;
  settingsData: SettingsData;
}) {
  const { store, latestRuleset } = props;
  const database = useDatabase();

  return (
    <ConfirmDialog
      store={store}
      heading={`Switch to latest ruleset?`}
      message={
        <>
          {`This action will switch your default ruleset to ${latestRuleset.versionName} and upgrade all armies to match. `}
          <Link href="/changelog/2024-07-08-hobgoblin-v1.0" className={interactiveText}>
            See changelog
          </Link>
          .
        </>
      }
      submit="Switch & Upgrade"
      submitTheme={primaryTheme}
      onSubmit={async () => {
        await database.migrateUserSettings(latestRuleset.version, props.settingsData);

        const { armiesData } = await database.getOutdatedItems(latestRuleset.version);

        for (const armyData of armiesData) {
          await database.updateArmy(migrateArmy(armyData));
        }
      }}
    />
  );
}

function UpgradeOutdatedDialog(props: {
  store: DialogStore;
  latestRuleset: Ruleset;
  settingsData: SettingsData;
}) {
  const { store, latestRuleset } = props;
  const database = useDatabase();

  return (
    <ConfirmDialog
      store={store}
      heading={`Upgrade outdated armies?`}
      message={
        <>
          {`Some of your armies use outdated rulesets. This action will upgrade them all to ${latestRuleset.versionName} (latest). `}
          <Link href="/changelog/2024-07-08-hobgoblin-v1.0" className={interactiveText}>
            See changelog
          </Link>
          .
        </>
      }
      submit="Upgrade"
      submitTheme={primaryTheme}
      onSubmit={async () => {
        const { armiesData } = await database.getOutdatedItems(latestRuleset.version);

        for (const armyData of armiesData) {
          await database.updateArmy(migrateArmy(armyData));
        }
      }}
    />
  );
}

function offerUpgrade(rulesets: Set<string>, latestVersion: string) {
  if (rulesets.size > 1) {
    return true;
  }

  if (rulesets.size === 1 && !rulesets.has(latestVersion)) {
    return true;
  }
}

export function RulesetVersionIndicator() {
  const { latestRuleset } = useGameContentLookup();
  const userSettingsOp = useUserSettingsOp();
  const usedArmyRulesetsOp = useArmiesRulesets();
  const { store, isOpen, setOpen } = useDialog();

  if (userSettingsOp.isPending || usedArmyRulesetsOp.isPending) {
    return <div className={css.version}>Loading...</div>;
  }

  if (userSettingsOp.isFailure || usedArmyRulesetsOp.isFailure) {
    return <div className={css.version}>Error</div>;
  }

  const userSettings = userSettingsOp.value;

  if (userSettings.ruleset.version < latestRuleset.version) {
    return (
      <>
        {isOpen && (
          <SwitchRulesetDialog
            store={store}
            latestRuleset={latestRuleset}
            settingsData={userSettings.data}
          />
        )}

        <div className={css.version}>
          Hobgoblin {userSettings.ruleset.versionName}
          {" — "}
          <button type="button" onClick={setOpen} className={css.updateButton}>
            Switch to latest?
          </button>
        </div>
      </>
    );
  }

  return (
    <div>
      {isOpen && (
        <UpgradeOutdatedDialog
          store={store}
          latestRuleset={latestRuleset}
          settingsData={userSettings.data}
        />
      )}

      <div className={css.version}>Ruleset {userSettings.ruleset.versionName}</div>

      {offerUpgrade(usedArmyRulesetsOp.value, latestRuleset.version) && (
        <button type="button" onClick={setOpen} className={css.updateButton}>
          Upgrade outdated armies
        </button>
      )}
    </div>
  );
}
