import { Army } from "@/domain/army";
import { getRandomArmyName } from "@/lib/name-generator";
import { ArmyImage } from "@/schema/latest";
import { useDatabase } from "@/ui/database-provider";
import { FormDialogV2 } from "@/ui/form-dialog";
import { InputsGroup } from "@/ui/inputs/group";
import { useDialogContext } from "@ariakit/react";
import { Failure, fold, Success } from "@indietabletop/appkit/async-op";
import { client } from "../client";
import { FormSubmitError } from "../form-submit-error";
import { toKnownFailureMessage, useForm } from "../hooks/use-form";
import { useNavigate } from "../hooks/use-navigate";
import { InlineButton } from "../inline-button";
import { FormTextAreaField, FormTextField } from "../inputs";
import { MainImagePicker, SelectedArmyImage } from "../main-image-picker";
import { useStagedImage } from "../main-image-picker/use-staged-image";
import { wrap } from "./wrap";

async function uploadStagedImages(images: (SelectedArmyImage | null)[]) {
  return fold(
    await Promise.all(
      images
        .filter((i) => !!i)
        .map(async (image) => {
          if (image.type === "STAGED") {
            const remoteSrcOp = await client.uploadImage(image.file);
            return remoteSrcOp
              .mapSuccess((src): ArmyImage => ({ type: "IMAGE_UPLOAD", src }))
              .mapFailure((failure) => {
                if (failure.type === "API_ERROR" && failure.code === 401) {
                  return `Failed to upload image. You must be logged in to upload images.`;
                }

                return toKnownFailureMessage(failure);
              });
          }

          return new Success(image);
        }),
    ),
  );
}

type FormValues = {
  name: string;
  description: string;
  images: ArmyImage[];
};

function ArmyFormDialog<T>(props: {
  heading: string;
  submitLabel: string;
  onSubmit: (armydata: FormValues) => Promise<Success<T> | Failure<string>>;
  onSuccess?: (successValue: T) => void;
  army?: Army;
}) {
  const { army } = props;

  const dialog = useDialogContext();
  const { stagedImage, setStagedImage } = useStagedImage(army?.mainImage ?? null);

  const { form, submitName } = useForm({
    defaultValues: {
      name: army?.data.name ?? "",
      description: army?.data.description ?? "",
    },

    async onSubmit({ values }) {
      const imagesOp = await uploadStagedImages([stagedImage]);

      if (imagesOp.isFailure) {
        return imagesOp;
      }

      return await props.onSubmit({ ...values, images: imagesOp.valueOrNull() ?? [] });
    },

    onSuccess(successValue) {
      props.onSuccess?.(successValue);
      dialog?.hide();
    },
  });

  return (
    <FormDialogV2 heading={props.heading} submitLabel={props.submitLabel} form={form}>
      <InputsGroup>
        <MainImagePicker stagedImage={stagedImage} setStagedImage={setStagedImage} />

        <FormTextField
          name={form.names.name}
          label="Name"
          placeholder="Eg. Blackmire Expedition"
          hint={
            <>
              Stuck?{" "}
              <InlineButton
                onClick={() => {
                  form.setValue(form.names.name, getRandomArmyName());
                }}
              >
                Generate a random name
              </InlineButton>
              .
            </>
          }
        />

        <FormTextAreaField
          name={form.names.description}
          label="Description"
          minRows={4}
          placeholder="Optionally add a backstory, how to play etc..."
        />

        <FormSubmitError name={submitName} />
      </InputsGroup>
    </FormDialogV2>
  );
}

export function CreateArmyDialog(props: { rulesetVersion: string }) {
  const navigate = useNavigate();
  const database = useDatabase();

  return (
    <ArmyFormDialog
      heading="Army"
      submitLabel="Create army"
      onSubmit={async (values) => {
        return await wrap(
          database.createArmy({ ...values, ruleset_version: props.rulesetVersion }),
        );
      }}
      onSuccess={(id) => {
        navigate(`~/army/a/${id}`);
      }}
    />
  );
}

export function EditArmyDialog(props: { army: Army }) {
  const { army } = props;
  const database = useDatabase();

  return (
    <ArmyFormDialog
      army={army}
      heading="Edit Army"
      submitLabel="Save"
      onSubmit={async (values) => {
        return await wrap(database.updateArmy({ ...army.data, ...values }));
      }}
    />
  );
}
