import { getIllustrationSrc, getImageUploadSrc } from "@/domain/s3-urls";
import { ArmyIllustration, ArmyImageUpload } from "@/schema/latest";
import {
  DialogDisclosure,
  FormGroupLabel,
  FormRadio,
  FormRadioGroup,
  FormRadioProps,
  useDialogContext,
  useFormContext,
  VisuallyHidden,
} from "@ariakit/react";
import { Success } from "@indietabletop/appkit/async-op";
import { useId, useRef } from "react";
import { armyPhotoSize } from "../army-detail-page/imageSizes";
import { Button } from "../button";
import { DialogTrigger } from "../dialog-trigger";
import { FormDialogV2 } from "../form-dialog";
import { useForm } from "../hooks/use-form";
import { Image } from "../image";
import { InlineButton } from "../inline-button";
import { Link } from "../link";
import { useSessionInfo } from "../store/hooks";
import { button, interactiveTextWhite, primaryTheme } from "../utils.css";
import * as css from "./style.css";
import { useStagedImage } from "./use-staged-image";

export type StagedArmyImageUpload = { type: "STAGED"; file: File; src: string };

export type SelectedArmyImage = ArmyImageUpload | StagedArmyImageUpload | ArmyIllustration;

const illustrationsByAuthor = {
  crom: [
    "DARK_KNIGHT",
    "DWARF_ARCHER",
    "BEAST",
    "FAMILIAR",
    "GOBLINS",
    "ICE_ZOMBIES",
    "OORM",
    "WOUNDED_ELF",
    "ELF_ARCHER",
    "ELF_WARRIOR",
    "HUNTER",
    "WIZARD",
  ],
  sean: [
    "CURSADERS",
    "DROGONOID_BARBARIAN",
    "DWARF_SPEARMAN",
    "EASTERN_HOBGOBLIN",
    "HILLTOP_MANOR",
    "PALADIN",
    "SEA_EMPIRE_SOLDIER",
  ],
};

const thumbSize = {
  width: 350,
  height: 350,
};

function IllustrationGroup(props: {
  label: string;
  name: FormRadioProps["name"];
  options: string[];
}) {
  const form = useFormContext();

  return (
    <FormRadioGroup>
      <FormGroupLabel className={css.groupLabel}>{props.label}</FormGroupLabel>

      <div className={css.groupRadiosGrid}>
        {props.options.map((illustrationId) => {
          return (
            <FormRadio
              key={illustrationId}
              value={illustrationId}
              name={props.name}
              onDoubleClick={() => void form?.submit()}
              render={
                <div className={css.radio({ aspectRatio: "square" })}>
                  <Image
                    {...thumbSize}
                    src={getIllustrationSrc(illustrationId)}
                    optimize
                    alt=""
                    style={{
                      inlineSize: "100%",
                      blockSize: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className={css.selectionFrame()} />
                </div>
              }
            />
          );
        })}
      </div>
    </FormRadioGroup>
  );
}

function ImageUploadButton(props: {
  name: FormRadioProps["name"];
  image: SelectedArmyImage | null;
  setStagedImage: (image: SelectedArmyImage | null) => void;
  stagedImage: ArmyImageUpload | StagedArmyImageUpload | null;
}) {
  const id = useId();
  const form = useFormContext();
  const ref = useRef<HTMLInputElement | null>(null);
  const session = useSessionInfo();

  return (
    <div className={css.imageUpload}>
      <VisuallyHidden>
        <input
          id={id}
          ref={ref}
          type="file"
          accept="image/*"
          onChange={(event) => {
            const file = event.target.files?.item(0);
            if (!file) {
              return;
            }

            props.setStagedImage({ type: "STAGED", file, src: URL.createObjectURL(file) });
            form?.setValue(props.name, "CUSTOM");
          }}
        />
      </VisuallyHidden>

      {props.stagedImage && (
        <FormRadio
          name={props.name}
          value="CUSTOM"
          render={
            <span className={css.radio({ aspectRatio: "photo" })}>
              <Image
                {...armyPhotoSize}
                src={
                  props.stagedImage.type === "STAGED" ?
                    props.stagedImage.src
                  : getImageUploadSrc(props.stagedImage.src)
                }
                className={css.imagePreview}
                // Don't optimize staged images — they are local!
                optimize={props.stagedImage.type === "IMAGE_UPLOAD"}
              />
              <span className={css.selectionFrame()} />
            </span>
          }
          onDoubleClick={() => {
            void form?.submit();
          }}
        />
      )}

      {session ?
        <label
          htmlFor={id}
          className={button}
          style={!props.stagedImage ? primaryTheme : undefined}
        >
          {props.stagedImage ? "Choose another" : "Choose from device"}
        </label>
      : <div className={css.lockedButton}>
          <div>
            <div className={css.lockedHeading}>Choose from device</div>
            <div className={css.lockedInfo}>
              {"A "}
              <span className={css.infoHighlight}>free</span>
              {" Indie Tabletop Club account is needed to upload images. "}
              <Link className={interactiveTextWhite} href="~/join">
                Join now
              </Link>
              {" or "}
              <Link className={interactiveTextWhite} href="~/login">
                log in
              </Link>
              .
            </div>
          </div>

          <svg
            className={css.lockedIcon}
            width="40px"
            height="40px"
            viewBox="0 0 40 40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" stroke-width="1" fill="white" fill-rule="evenodd">
              <path d="M19.75,9 C17.67893,9 16,10.67893 16,12.75 L16,16.13666 C14.19102,17.3476 13,19.4097 13,21.75 C13,25.4779 16.02208,28.5 19.75,28.5 C23.4779,28.5 26.5,25.4779 26.5,21.75 C26.5,19.4097 25.309,17.3476 23.5,16.13666 L23.5,12.75 C23.5,10.67893 21.8211,9 19.75,9 Z M22,15.38409 L22,12.75 C22,11.50736 20.9926,10.5 19.75,10.5 C18.5074,10.5 17.5,11.50736 17.5,12.75 L17.5,15.38409 C18.2038,15.13535 18.9611,15 19.75,15 C20.5389,15 21.2962,15.13535 22,15.38409 Z M18,19.75 C18,18.7835 18.7835,18 19.75,18 C20.7165,18 21.5,18.7835 21.5,19.75 C21.5,20.4481 21.0912,21.0507 20.5,21.3316 L20.5,22.75 C20.5,23.1642 20.1642,23.5 19.75,23.5 C19.3358,23.5 19,23.1642 19,22.75 L19,21.3316 C18.4088,21.0507 18,20.4481 18,19.75 Z"></path>
            </g>
          </svg>
        </div>
      }

      {props.stagedImage && (
        <Button
          className={button}
          onClick={() => {
            props.setStagedImage(null);
            form?.setValue(props.name, "");

            // Clear hidden file input field so that if a file is selected again it actually shows.
            // (If left uncleared, the 'change' even would not fire after re-selection...)
            if (ref.current) {
              ref.current.value = "";
            }
          }}
        >
          Clear
        </Button>
      )}
    </div>
  );
}

export function MainImageDialog(props: {
  image: SelectedArmyImage | null;
  onSubmit: (image: SelectedArmyImage | null) => void;
}) {
  const { image } = props;

  const dialog = useDialogContext();

  const { stagedImage, setStagedImage } = useStagedImage(
    image && image.type !== "ILLUSTRATION" ? image : null,
  );

  const { form } = useForm({
    defaultValues: {
      illustrationId:
        image ?
          image.type === "ILLUSTRATION" ?
            image.id
          : "CUSTOM"
        : "",
    },

    onSubmit({ values }): Success<SelectedArmyImage | null> {
      // If illustration ID is falsy, it means the user has cleared the field -- they don't want
      // any army illustration.
      if (!values.illustrationId) {
        return new Success(null);
      }

      // If illustration ID is CUSTOM, it means they are uploading their own image. We pass the
      // currently staged image.
      if (values.illustrationId === "CUSTOM") {
        return new Success(stagedImage);
      }

      return new Success({
        type: "ILLUSTRATION",
        id: values.illustrationId,
      });
    },

    onSuccess(image) {
      props.onSubmit(image);
      dialog?.hide();
    },
  });

  return (
    <FormDialogV2 heading="Main Image" submitLabel="Confirm selection" form={form}>
      <ImageUploadButton
        name={form.names.illustrationId}
        image={props.image}
        stagedImage={
          stagedImage?.type === "STAGED" || stagedImage?.type === "IMAGE_UPLOAD" ?
            stagedImage
          : null
        }
        setStagedImage={setStagedImage}
      />

      <div
        style={{ fontSize: "1rem", fontStyle: "italic", textAlign: "center", marginBlock: "2rem" }}
      >
        — or —
      </div>

      <div className={css.groupsStack}>
        <IllustrationGroup
          label="Illustrations from CROM"
          name={form.names.illustrationId}
          options={illustrationsByAuthor.crom}
        />

        {/* <IllustrationGroup
          label="Illustrations from Sean Sutter"
          name={form.names.illustrationId}
          options={illustrationsByAuthor.sean}
        /> */}
      </div>
    </FormDialogV2>
  );
}

function ImagePicker(props: ReturnType<typeof useStagedImage>) {
  const { stagedImage } = props;

  const info = (
    <div>
      <DialogDisclosure focusable={false} className={css.pickerLabel} render={<div />}>
        Main image
      </DialogDisclosure>

      <div className={css.pickerHint}>
        Select or upload army image
        {!!stagedImage && (
          <>
            {". "}
            <InlineButton onClick={() => props.setStagedImage(null)}>Clear</InlineButton>
          </>
        )}
      </div>
    </div>
  );

  if (!stagedImage) {
    return (
      <div className={css.picker}>
        <DialogDisclosure className={css.radio({ aspectRatio: "square", size: "small" })}>
          +
          <div className={css.selectionFrame({ thinBorder: true })} />
        </DialogDisclosure>

        {info}
      </div>
    );
  }

  if (stagedImage.type === "ILLUSTRATION") {
    return (
      <div className={css.picker}>
        <DialogDisclosure className={css.radio({ aspectRatio: "square", size: "large" })}>
          <Image
            {...thumbSize}
            className={css.stagedImage}
            src={getIllustrationSrc(stagedImage.id)}
            optimize
          />

          <div className={css.selectionFrame({ thinBorder: true })} />
        </DialogDisclosure>

        {info}
      </div>
    );
  }

  const isStagedImageUpload = stagedImage.type === "STAGED";

  return (
    <div className={css.picker}>
      <DialogDisclosure className={css.radio({ aspectRatio: "photo", size: "large" })}>
        <Image
          {...thumbSize}
          className={css.stagedImage}
          src={isStagedImageUpload ? stagedImage.src : getImageUploadSrc(stagedImage.src)}
          optimize={!isStagedImageUpload}
        />

        <div className={css.selectionFrame({ thinBorder: true })} />
      </DialogDisclosure>

      {info}
    </div>
  );
}

export function MainImagePicker(props: ReturnType<typeof useStagedImage>) {
  return (
    <DialogTrigger>
      <MainImageDialog image={props.stagedImage} onSubmit={props.setStagedImage} />

      <ImagePicker {...props} />
    </DialogTrigger>
  );
}
