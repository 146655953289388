import { FormSubmit, FormSubmitProps, useFormContext, useStoreState } from "@ariakit/react";
import { classNames } from "@indietabletop/appkit/class-names";
import { fadeIn } from "../animations.css";
import { LoadingIndicatorV2 } from "../loading-indicator";
import * as common from "../utils.css";
import * as css from "./style.css";

export function FormSubmitButton(props: FormSubmitProps & { label: string }) {
  const { label, className, style, ...submitProps } = props;
  const form = useFormContext();
  const isSubmitting = useStoreState(form, (s) => s?.submitting);

  return (
    <FormSubmit
      {...submitProps}
      className={classNames(common.button, className)}
      style={{ position: "relative", ...style, ...common.primaryTheme }}
    >
      <span style={{ opacity: isSubmitting ? 0 : 1, transition: "200ms opacity" }}>{label}</span>

      {isSubmitting && (
        <div
          className={css.indicatorWrapper}
          style={{
            animation: `${fadeIn} 200ms 200ms both`,
          }}
        >
          <LoadingIndicatorV2 className={css.pending} />
        </div>
      )}
    </FormSubmit>
  );
}
