export function getOptimizedUrl(props: {
  src: string;
  width: number;
  height: number;
  fit?: "contain" | "cover";
}) {
  const params = new URLSearchParams({
    url: props.src,
    fit: props.fit ?? "contain",
    w: props.width.toString(),
    h: props.height.toString(),
  });

  return `/.netlify/images?${params}`;
}
