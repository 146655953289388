import { useRef, useState, useEffect } from "react";

export function useObserveVisibility<E extends HTMLElement>({ threshold }: { threshold: number }) {
  const ref = useRef<E>(null);
  const [isFullyVisible, setIsFullyVisible] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      // We are only ever observing a single entry, so we can safely grab the
      // first entry in the list.
      ([entry]) => {
        if (!entry) {
          return;
        }

        setIsFullyVisible(entry.isIntersecting);
      },
      { threshold },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [threshold]);

  return [ref, isFullyVisible] as const;
}
