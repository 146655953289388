import rerollImageWhite from "@/images/reroll-white.svg";
import rerollImage from "@/images/reroll.svg";
import { Image } from "@/ui/image";
import { SectionId } from "../../lib/user-settings";
import { MinWidth } from "../../ui/media";
import { useUserSettingsOp } from "../../ui/user-settings/context";
import { useBattleStateOp } from "../battle-state/context";
import * as css from "./style.css";

export function RerollSectionRibbon(props: { sectionId: SectionId }) {
  const userSettingsOp = useUserSettingsOp();
  const battleStateResult = useBattleStateOp();

  // If battle state has not loaded or settings have not loaded
  // don't render this component.
  if (!userSettingsOp.isSuccess || !battleStateResult.isSuccess) {
    return null;
  }

  const battleSettings = userSettingsOp.value;
  const battleState = battleStateResult.value.state;
  const battleActions = battleStateResult.value.actions;

  // If we have a version mismatch between the state and settings
  // (probably because this battle state was shared via URL), we
  // don't allow people to reroll individual sections as that
  // would create a state where sections are made out of different
  // versions of the game.
  if (battleState.ruleset.version !== battleSettings.ruleset.version) {
    return null;
  }

  return (
    <div className={css.ribbon}>
      <button
        type="button"
        onClick={() => {
          battleActions.rerollSection(props.sectionId);
        }}
        className={css.button}
      >
        <picture>
          <source srcSet={rerollImageWhite} media={MinWidth.MEDIUM} width={39} height={37} />
          <Image
            src={rerollImage}
            alt="Reroll section"
            className={css.icon}
            width={39}
            height={37}
          />
        </picture>
      </button>
    </div>
  );
}
