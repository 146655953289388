import { useDatabase } from "@/ui/database-provider";
import { KnownErrorMessage } from "@/ui/db-error";
import { Checkbox } from "@/ui/inputs/checkbox";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { useId } from "react";
import { SectionId } from "../../lib/user-settings";
import { useUserSettingsOp } from "../../ui/user-settings/context";
import * as css from "./style.css";

export function Exclusions(props: {
  legend: string;
  sectionId: SectionId;
  entries: { id: string; name: string }[];
  excluded: string[];
  minOptions: number;
}) {
  const labelId = useId();

  const database = useDatabase();
  const userSettingsOp = useUserSettingsOp();

  if (userSettingsOp.isPending) {
    return <LoadingIndicator />;
  }

  if (userSettingsOp.isFailure) {
    return <KnownErrorMessage error={userSettingsOp.failure} />;
  }

  const battleSettings = userSettingsOp.value;
  const excluded = battleSettings.getExcluded(props.sectionId);

  const remaining = props.entries.length - (excluded.length + props.minOptions);
  const maximumSelected = remaining === 0;

  return (
    <div className={css.fieldset} role="group" aria-labelledby={labelId}>
      <div className={css.legend} id={labelId}>
        {props.legend}
      </div>
      <ul className={css.toggles}>
        {props.entries.map((entry) => {
          const isChecked = excluded.includes(entry.id);

          return (
            <li key={entry.id} className={css.toggle}>
              <Checkbox
                name={entry.id}
                label={entry.name}
                checked={isChecked}
                disabled={!isChecked && maximumSelected}
                onChecked={() => {
                  void database.addEntryToExcludedItems(
                    battleSettings.data,
                    props.sectionId,
                    entry.id,
                  );
                }}
                onUnchecked={() => {
                  void database.removeEntryFromExcludedItems(
                    battleSettings.data,
                    props.sectionId,
                    entry.id,
                  );
                }}
              />
            </li>
          );
        })}
      </ul>
      <div className={css.info}>
        {maximumSelected ?
          `You cannot exclude any more items. There need to be at least ${props.minOptions} items to choose from.`
        : excluded.length === 0 ?
          `You can exclude up to ${remaining} items.`
        : remaining > 1 ?
          `You can exclude up to ${remaining} more items.`
        : `You can exclude 1 more item.`}
        {excluded.length > 0 && (
          <>
            {" "}
            <button
              type="button"
              className={css.resetButton}
              onClick={() => {
                void database.resetExcludedItemsForSection(battleSettings.data, props.sectionId);
              }}
            >
              Deselect all
            </button>
            {"."}
          </>
        )}
      </div>
    </div>
  );
}
