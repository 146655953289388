import { CSSProperties } from "react";
import { getOptimizedUrl } from "./getOptimizedUrl";

export function Image(props: {
  src: string;
  alt?: string;
  width: number;
  height: number;
  className?: string;
  style?: CSSProperties;
  optimize?: boolean;
}) {
  const { src, alt, optimize, ...imgProps } = props;
  return <img {...imgProps} src={optimize ? getOptimizedUrl(props) : src} alt={alt ?? ""} />;
}
