import { useMediaQuery } from "@indietabletop/appkit/use-media-query";
import { ReactNode } from "react";
import { MinWidth } from "../media";
import { MobileNav, MobileNavProps } from "../spine";
import * as css from "./style.css";

export function MobileNavLayout(props: MobileNavProps & { children: ReactNode }) {
  const withNav = !useMediaQuery(MinWidth.MEDIUM);
  const { children, ...navProps } = props;

  return (
    <>
      {withNav && <MobileNav {...navProps} />}
      <div className={css.content({ withNav })}>{children}</div>
    </>
  );
}
