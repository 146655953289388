import { Form } from "@ariakit/react";
import { CurrentUser } from "@indietabletop/appkit/types";
import { Dispatch, SetStateAction, useState } from "react";
import { client } from "../client";
import { FormSubmitButton } from "../form-submit-button";
import { FormSubmitError } from "../form-submit-error";
import { toKnownFailureMessage, useForm } from "../hooks/use-form";
import { IndieTabletopClubFooter, IndieTabletopClubSymbol } from "../indie-tabletop-club-brand";
import { FormTextField } from "../inputs";
import { InputsGroup } from "../inputs/group";
import { Link } from "../link";
import { ViewContainer } from "../max-width-container";
import { MobileNavLayout } from "../mobile-nav-layout";
import { useCurrentUser } from "../store/hooks";
import {
  button,
  formHeader,
  formSubmitArea,
  heading,
  interactiveText,
  paragraph,
  primaryTheme,
  section,
} from "../utils.css";

type SetStep = Dispatch<SetStateAction<Steps>>;

function InitialStep(props: { setStep: SetStep; currentUser: CurrentUser }) {
  const { email, isVerified: isVerified } = props.currentUser;
  const { form, submitName } = useForm({
    defaultValues: {},
    async onSubmit() {
      const op = await client.requestUserVerification();
      return op.mapFailure(toKnownFailureMessage);
    },
    onSuccess(value) {
      props.setStep({ type: "SUBMIT_CODE", tokenId: value.tokenId });
    },
  });

  if (isVerified) {
    return (
      <>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />
          <h1 className={heading}>Already Verified</h1>
          <p className={paragraph}>Your user account has already been verified. You're all good!</p>
          <p className={paragraph}>
            <Link href="~/" className={interactiveText}>
              Back to dashboard
            </Link>
          </p>
        </div>
        <IndieTabletopClubFooter />
      </>
    );
  }

  return (
    <div>
      <Form store={form}>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />

          <h1 className={heading}>Verify account</h1>
          <p className={paragraph}>
            Your account is currently not verified. We will send a one-time code to your email (
            {email}) to complete the verification.
          </p>
        </div>

        <FormSubmitError name={submitName} />

        <div className={formSubmitArea}>
          <FormSubmitButton label="Send code" />
        </div>
        <IndieTabletopClubFooter />
      </Form>
    </div>
  );
}

function SubmitCodeStep(props: { tokenId: string; setStep: SetStep; currentUser: CurrentUser }) {
  const { email } = props.currentUser;
  const { form, submitName } = useForm({
    defaultValues: {
      code: "",
    },
    async onSubmit({ values }) {
      const op = await client.verifyUser({
        ...values,
        tokenId: props.tokenId,
      });

      return op.mapFailure((failure) => {
        if (failure.type === "API_ERROR" && failure.code === 404) {
          return "This code is incorrect or expired. Please try again.";
        }

        return toKnownFailureMessage(failure);
      });
    },
    onSuccess() {
      props.setStep({ type: "SUCCESS" });
    },
  });

  return (
    <div>
      <Form store={form}>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />
          <h1 className={heading}>Verify Account</h1>
          <p className={paragraph}>
            We've sent a one-time code to {email}. Please, enter the code in the field below to
            complete verification.
          </p>
        </div>

        <InputsGroup>
          <FormTextField
            name={form.names.code}
            label="Code"
            placeholder="E.g. 123123"
            autoComplete="one-time-code"
            required
          />

          <FormSubmitError name={submitName} />
        </InputsGroup>

        <div className={formSubmitArea}>
          <FormSubmitButton label="Verify" />
        </div>
        <IndieTabletopClubFooter />
      </Form>
    </div>
  );
}

function SuccessStep() {
  return (
    <div>
      <div className={formHeader}>
        <IndieTabletopClubSymbol />
        <h1 className={heading}>Success!</h1>
        <p className={paragraph}>
          Your Indie Tabletop Club account has been verified. Enjoy using the Hobgoblin App!
        </p>
      </div>

      <p className={paragraph}>
        <Link href="~/" className={button} style={primaryTheme}>
          Go to dashboard
        </Link>
      </p>
      <IndieTabletopClubFooter />
    </div>
  );
}

type Steps = { type: "INITIAL" } | { type: "SUBMIT_CODE"; tokenId: string } | { type: "SUCCESS" };

function VerifyPageSwitch() {
  const currentUser = useCurrentUser();
  const [step, setStep] = useState<Steps>({ type: "INITIAL" });

  if (!currentUser) {
    return (
      <>
        <div className={formHeader}>
          <IndieTabletopClubSymbol />
          <h1 className={heading}>Cannot verify</h1>
          <p className={paragraph}>You must be logged in to verify your account.</p>
        </div>
        <IndieTabletopClubFooter />
      </>
    );
  }

  switch (step.type) {
    case "INITIAL": {
      return <InitialStep setStep={setStep} currentUser={currentUser} />;
    }

    case "SUBMIT_CODE": {
      return <SubmitCodeStep {...step} setStep={setStep} currentUser={currentUser} />;
    }

    case "SUCCESS": {
      return <SuccessStep />;
    }
  }
}

export function VerifyPage() {
  return (
    <MobileNavLayout title="Verify">
      <ViewContainer padding="small">
        <div className={section}>
          <VerifyPageSwitch />
        </div>
      </ViewContainer>
    </MobileNavLayout>
  );
}
