import { useScrollRestoration as useScrollRestorationHook } from "@indietabletop/appkit/use-scroll-restoration";
import { usePathname } from "./use-pathname";

const neverRestore = [
  "/",
  "/quick-start/keywords",
  "/quick-start/profiles",
  "/quick-start/target-numbers",
];

export function useScrollRestoration() {
  const pathname = usePathname();
  useScrollRestorationHook(pathname, { neverRestore });
}
