import { Army } from "@/domain/army";
import { Unit } from "@/domain/unit";
import { UnitType } from "@/domain/unit-type";
import { stringifyUnitType } from "@/domain/units";
import { FormDialogV2 } from "@/ui/form-dialog";
import { useRuleset } from "@/ui/game-content-provider";
import { FormRadioGroupField, FormTextAreaField, FormTextField } from "@/ui/inputs";
import { InputsGroup } from "@/ui/inputs/group";
import { useDialogContext } from "@ariakit/react";
import { Failure, Success } from "@indietabletop/appkit/async-op";
import { useState } from "react";
import { wrap } from "../army-form-dialog/wrap";
import { useDatabase } from "../database-provider/hooks";
import { useForm } from "../hooks/use-form";
import { useNavigate } from "../hooks/use-navigate";

type UnitFormValues = {
  name: string;
  description: string;
  type: UnitType;
};

export function UnitFormDialog<T>(props: {
  heading: string;
  submitLabel: string;
  unit?: Unit;
  onSubmit: (values: UnitFormValues) => Promise<Success<T> | Failure<string>>;
  onSuccess?: (successValue: T) => void;
}) {
  const defaultValues = props.unit?.data;

  const ruleset = useRuleset();
  const [type, setType] = useState(defaultValues?.type ?? UnitType.LIGHT_INFANTRY);

  const dialog = useDialogContext();
  const { form } = useForm({
    defaultValues: {
      name: defaultValues?.name ?? "",
      description: defaultValues?.description ?? "",
      type: defaultValues?.type ?? UnitType.LIGHT_INFANTRY,
    },
    async onSubmit({ values }) {
      return await props.onSubmit(values);
    },
    onSuccess(value) {
      props.onSuccess?.(value);
      dialog?.hide();
    },
  });

  return (
    <FormDialogV2 form={form} heading={props.heading} submitLabel={props.submitLabel}>
      <InputsGroup>
        <FormTextField name={form.names.name} label="Name" placeholder="Eg. Darkswords" />

        <FormTextAreaField
          name={form.names.description}
          label="Description"
          placeholder="Optionally, add a description for this unit."
        />

        <FormRadioGroupField
          name={form.names.type}
          label="Type"
          options={ruleset.unitProfiles.map((profile) => {
            return {
              label: stringifyUnitType(profile.type),
              value: profile.type,
              checked: profile.type === type,
              extra: `${profile.points}pts`,
              onChange: () => setType(profile.type),
            };
          })}
        />
      </InputsGroup>
    </FormDialogV2>
  );
}

export function AddUnitDialog(props: { army: Army }) {
  const database = useDatabase();
  const navigate = useNavigate();

  return (
    <UnitFormDialog
      heading="Unit"
      submitLabel="Add Unit"
      onSubmit={(values) => wrap(database.addUnit(props.army.id, values))}
      onSuccess={(unitId) => navigate(`/u/${unitId}`)}
    />
  );
}

export function EditUnitDialog(props: { unit: Unit }) {
  const database = useDatabase();

  return (
    <UnitFormDialog
      {...props}
      heading="Edit Unit"
      submitLabel="Save"
      onSubmit={(values) => wrap(database.updateUnit(props.unit.army.id, props.unit.id, values))}
    />
  );
}
