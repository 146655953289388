import { Failure, Success } from "@indietabletop/appkit/async-op";
import { caughtValueToString } from "@indietabletop/appkit/caught-value";

/**
 * Wraps supplied promise in Success/Failure
 *
 * This is useful in places where the app expects an Async Op, but you are only dealing with
 * native promises.
 */
export async function wrap<T>(promise: Promise<T>) {
  try {
    const result = await promise;
    return new Success(result);
  } catch (error) {
    return new Failure(caughtValueToString(error));
  }
}
