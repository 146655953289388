import { button, primaryTheme } from "@/ui/utils.css";
import { DialogDisclosure } from "@ariakit/react";
import { CreateArmyDialog } from "../army-form-dialog";
import { DialogTrigger } from "../dialog-trigger";

export function CreateArmyButton(props: {
  theme?: Record<string, string>;
  rulesetVersion: string;
}) {
  return (
    <DialogTrigger>
      <CreateArmyDialog {...props} />

      <DialogDisclosure className={button} style={props.theme ?? primaryTheme}>
        Create Army
      </DialogDisclosure>
    </DialogTrigger>
  );
}
