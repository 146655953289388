import { HobgoblinDatabase } from "@/lib/db";
import { ArmyProvider } from "@/ui/army-provider";
import { useQuery } from "@/ui/database-provider/hooks";
import { ReactNode, useCallback } from "react";

export function LocalArmyProvider(props: { armyId: string; children: ReactNode }) {
  const getArmy = useCallback(
    async (db: HobgoblinDatabase) => (await db.getArmy(props.armyId)) ?? null,
    [props.armyId],
  );
  const result = useQuery(getArmy, `army:${props.armyId}`);

  return <ArmyProvider armyDataOp={result}>{props.children}</ArmyProvider>;
}
