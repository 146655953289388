import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { useRuleset } from "./game-content-provider";
import { useDialog } from "./hooks/use-dialog";
import { KeywordPopup } from "./keyword-popup";

type Payload = {
  id: string;
} | null;

const Keywords = createContext<(payload: Payload) => void>(() => {});

export const useKeywords = () => useContext(Keywords);

export function KeywordsHandler(props: { children: ReactNode }) {
  const ruleset = useRuleset();
  const [keyword, setKeyword] = useState<{ id: string } | null>(null);
  const { isOpen, setOpen, setClosed, store } = useDialog();

  const set = useCallback(
    (payload: Payload) => {
      if (payload) {
        setKeyword(payload);
        setOpen();
      } else {
        setKeyword(null);
        setClosed();
      }
    },
    [setClosed, setOpen],
  );

  return (
    <Keywords.Provider value={set}>
      {props.children}

      {isOpen && keyword && (
        <KeywordPopup keywordDef={ruleset.keywordsDefsById.getOrThrow(keyword.id)} store={store} />
      )}
    </Keywords.Provider>
  );
}
